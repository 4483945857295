import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import { isLoggedIn, logout } from '../services/auth'
import config from '../utils/siteConfig'

const Wrapper = styled.footer`
  margin-top: 0;
  background-color: white;
  color: ${(props) => props.theme.colors.darkText};
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.colors.lightBorder};

  .fill--nearly-white + & {
    border-color: transparent;
  }
`

const HorizontalLinks = styled.ul`
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  li {
    a {
      display: inline-flex;
      padding: 10px 10px;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      line-height: 18px;
      color: inherit;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 374px) {
    li {
      a {
        > span {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 320px) {
    display: flex;

    li {
      + li {
        &::before {
          content: '';
          position: relative;
          top: 3px;
          display: inline-block;
          height: 15px;
          border-left: 1px solid currentColor;
        }
      }
    }
  }

  @media screen and (min-width: 375px) {
    li {
      a {
        padding: 10px 14px;
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    margin-bottom: 25px;

    li {
      a {
        font-size: 14px;
      }
    }
  }
`

const SocialLinks = styled(HorizontalLinks)`
  @media screen and (min-width: 320px) {
    li {
      + li {
        &::before {
          content: none;
        }
      }
    }
  }
`

const Copyright = styled.p`
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 15px;

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    font-size: 12px;
    line-height: 12px;
    margin-top: 10px;
  }
`

const Footer = () => (
  <Wrapper className="wrap padded-section">
    <SocialLinks>
      <li className="social-link">
        <a
          href="https://www.facebook.com/groups/1829586323964956/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Facebook width="9px" />
        </a>
      </li>
      <li className="social-link">
        <a
          href="https://www.instagram.com/seintgallery/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram width="18px" />
        </a>
      </li>
    </SocialLinks>
    <HorizontalLinks>
      <li>
        <Link to="/support/">FAQ</Link>
      </li>
      <li>
        <a
          href="https://www.seintofficial.com/en/policies-procedures/us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Policies<span>&nbsp;&amp;&nbsp;Procedures</span>
        </a>
      </li>
      <li>
        <Link to="/support/contact/">Contact</Link>
      </li>
      {isLoggedIn() && (
        <li>
          <a
            href="/"
            onClick={(event) => {
              event.preventDefault()
              logout(() => window?.location?.reload())
            }}
          >
            Logout
          </a>
        </li>
      )}
    </HorizontalLinks>
    <Copyright>
      {config.copyright} {config.siteTitleAlt}
    </Copyright>
  </Wrapper>
)

export default Footer
