import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { PageContext } from '../context/ApplicationState'
import Container from './Container'
import Layout from './Layout'
import PageHeader from './PageHeader'
import SEO from './SEO'
import StickyContainer from './StickyContainer'

const NewsLayout = ({ location, children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: false,
      hasHeroSection: true,
    }))
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "News" } }) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
      allContentfulArticleCategory(sort: { fields: order }) {
        edges {
          node {
            title
            slug
            id
            showInNav
          }
        }
      }
    }
  `)

  const heroImageMobile =
    data.allContentfulPageContext.edges[0].node.heroImageMobile
  const heroImageDesktop =
    data.allContentfulPageContext.edges[0].node.heroImageDesktop

  const categoryLinks = data.allContentfulArticleCategory.edges

  const CategoryLinks = () =>
    categoryLinks.map(
      category =>
        category.node.showInNav && (
          <Link
            key={category.node.id}
            to={`/news/category/${category.node.slug}/`}
            activeClassName="active-page-link"
          >
            {category.node.title}
          </Link>
        )
    )

  return (
    <Layout {...props}>
      <SEO />

      <PageHeader
        imageMobile={heroImageMobile}
        imageDesktop={heroImageDesktop}
        title={props.pageName}
      />
      <StickyContainer
        className="fill--nearly-white"
        stickyNavLinks={
          <>
            <Link to="/news/" activeClassName="active-page-link">
              All News
            </Link>
            <CategoryLinks />
          </>
        }
      >
        <Container className="padded-section wrap">{children}</Container>
      </StickyContainer>
    </Layout>
  )
}

export default NewsLayout
