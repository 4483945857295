import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { PageContext } from '../context/ApplicationState'
import Container from './Container'
import Layout from './Layout'
import PageHeader from './PageHeader'
import SEO from './SEO'
import StickyContainer from './StickyContainer'

const FoundationLayout = ({ children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: false,
      hasHeroSection: true,
    }))
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "Foundation" } }) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
    }
  `)

  return (
    <Layout {...props}>
      <SEO />

      <PageHeader
        imageMobile={
          data.allContentfulPageContext.edges[0].node.heroImageMobile
        }
        imageDesktop={
          data.allContentfulPageContext.edges[0].node.heroImageDesktop
        }
        title={props.pageName}
      />
      <StickyContainer
        stickyNavLinks={
          <>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://loveisneverwasted.com/"
            >
              Love is Never Wasted ↗
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://angelslandingfoundation.org/"
            >
              Angels Landing ↗
            </a>
          </>
        }
      >
        <Container className="wrap section">{children}</Container>
      </StickyContainer>
    </Layout>
  )
}

export default FoundationLayout
