// Example
// {
//   id: 370,
//  // title: "Trust",
//  // image: "https://storage.googleapis.com/krato-kratoconsulting/product-images/110074/0.jpg",
//  stockLevel: {
//    out: 300,
//    low: 600,
//  },
//  discontinued: false,
//  discontinuedMsg: 'New packaging coming soon',
//  restockETA: 'End of July',
// },

const stockLevelLimits = {
  US: {
    makeup: {
      out: 300,
      low: 600,
    },
    skincare: {
      out: 150,
      low: 300,
    },
    skincareMen: {
      out: 100,
      low: 200,
    },
    brush: {
      out: 300,
      low: 600,
    },
    tool: {
      out: 75,
      low: 150,
    },
    compact: {
      out: 75,
      low: 150,
    },
    artistPalette: {
      out: 50,
      low: 100,
    },
    clutch: {
      out: 100,
      low: 200,
    },
    fragrance: {
      out: 50,
      low: 100,
    },
    artistKit: {
      out: 0,
      low: 100,
    },
  },
  Canada: {
    makeup: {
      out: 50,
      low: 100,
    },
    skincare: {
      out: 25,
      low: 50,
    },
    skincareMen: {
      out: 17,
      low: 34,
    },
    brush: {
      out: 50,
      low: 100,
    },
    tool: {
      out: 50,
      low: 100,
    },
    compact: {
      out: 25,
      low: 50,
    },
    artistPalette: {
      out: 17,
      low: 34,
    },
    clutch: {
      out: 17,
      low: 34,
    },
    fragrance: {
      out: 25,
      low: 50,
    },
    artistKit: {
      out: 0,
      low: 50,
    },
  },
}

const warehouseProducts = [
  {
    id: 1,
    name: 'United States',
    products: [
      // ***************
      // * Highlight
      // ***************
      {
        id: 7,
        // title: 'Moonlit Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 8,
        // title: 'White Peach Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 9,
        // title: 'Aura Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100002/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 10,
        // title: 'Sunlit Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100003/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 11,
        // title: 'Amber Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100004/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 13,
        // title: 'Wheat Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100005/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 14,
        // title: 'Mango Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100006/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 15,
        // title: 'Cinnamon Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100007/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 16,
        // title: 'Goddess Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100008/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 17,
        // title: 'Papaya Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100009/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 18,
        // title: 'June Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100010/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 249,
        // title: 'Linen Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100011/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 324,
        // title: 'Sandy Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100012/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 325,
        // title: 'Candlelit Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100013/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 489,
        // title: 'Mirage Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100014/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 490,
        // title: 'Muse Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100015/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 491,
        // title: 'Icon Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100016/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Contour
      // ***************
      {
        id: 19,
        // title: 'Walnut Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100100/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 20,
        // title: 'Stone Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100101/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 21,
        // title: 'Ash Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100102/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 22,
        // title: 'Shadow Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100103/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 23,
        // title: 'Indigo Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100104/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 24,
        // title: 'Olive Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100105/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 125,
        // title: 'Aspen Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100106/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 248,
        // title: 'Astoria Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100107/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 286,
        // title: 'Cola Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100108/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 492,
        // title: 'Henna Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100109/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Lip + Cheek
      // ***************
      {
        id: 25,
        // title: 'Pink Grapefruit Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100200/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 26,
        // title: 'Dahlia Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100201/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 27,
        // title: 'Ruby Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100202/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 28,
        // title: 'Desert Sunset Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100203/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 29,
        // title: 'Baby Watermelon Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100204/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 30,
        // title: 'Black Cherry Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100205/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 31,
        // title: 'Petal Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100206/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 32,
        // title: 'Nude Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100207/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 33,
        // title: 'Frenchie Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100208/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 34,
        // title: 'Bare Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100209/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 35,
        // title: 'Sandstone Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100210/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 36,
        // title: 'Hollywood Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100211/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 37,
        // title: 'Ever Red Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100212/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 245,
        // title: 'Scarlet Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100213/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 246,
        // title: 'Plum Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100214/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 382,
        // title: 'Tropicana Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100215/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 383,
        // title: 'Ballerina Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100216/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 384,
        // title: 'Royal Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100217/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 385,
        // title: 'Lolli Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100218/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 386,
        // title: 'Hopelessly Devoted Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100219/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 387,
        // title: 'Sadie Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100220/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 388,
        // title: 'Sunshine State Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100221/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 389,
        // title: 'Summer Love Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100222/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 493,
        // title: 'Lovespell Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100223/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 494,
        // title: 'Saffron Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100224/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 495,
        // title: 'Cindy Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100225/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      // *
      // * New Releases
      // *
      // {
      //   id: 515,
      //   // title: 'La Cienega Blvd Lip + Cheek ',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100229/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 524,
      //   // title: 'Suede Lip Liner ',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100600/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 538,
      //   // title: 'Royal Lipstick Tube',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100601/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: true,
      // },

      // ***************
      // * Illuminator
      // ***************
      {
        id: 38,
        // title: 'Pearl Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100300/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 39,
        // title: 'Honey Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100301/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 40,
        // title: 'Rose Gold Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100302/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 244,
        // title: 'Glow Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100304/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 390,
        // title: 'Angel Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100305/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 391,
        // title: 'Photoshop Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100403/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 392,
        // title: 'Goldie Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100404/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 393,
        // title: 'Georgia Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100405/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 394,
        // title: 'Glamazing Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100406/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 395,
        // title: 'Starlet Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100407/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 573,
        // title: 'Dulce Illuminator',
        // image: 'https://s3.amazonaws.com/seintofficial.com/product-images/100310/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      // *
      // * New Releases
      // *
      // {
      //   id: 518,
      //   // title: 'Prism Illuminator',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100306/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 519,
      //   // title: 'Sugar Illuminator',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100307/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: false,
      // },

      // ***************
      // * Setting Powder
      // ***************
      {
        id: 42,
        // title: 'Vanilla Dust Setting Powder',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100401/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },

      // ***************
      // * Bronzer
      // ***************
      {
        id: 41,
        // title: 'Cayman Bronzer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100400/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 126,
        // title: 'Bella Bronzer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100402/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      // *
      // * New Releases
      // *
      // {
      //   id: 520,
      //   // title: 'Barcelona Bronzer',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100410/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 521,
      //   // title: 'Tiki Cream Bronzer ',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100411/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 522,
      //   // title: 'Heatwaves Cream Bronzer',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100412/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 523,
      //   // title: 'Cabana Cream Bronzer',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100413/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.makeup.out,
      //     low: stockLevelLimits.US.makeup.low,
      //   },
      //   discontinued: false,
      // },

      // ***************
      // * Eyeshadow
      // ***************
      {
        id: 327,
        // title: 'Lala land',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110031/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 328,
        // title: 'Graceland',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110032/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 329,
        // title: 'Dollywood',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110033/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 330,
        // title: 'Shenandoah',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110034/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 331,
        // title: 'Tawanda',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110035/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 332,
        // title: 'Glass Slipper',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110036/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 333,
        // title: 'Gold Digger',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110037/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 334,
        // title: 'Soul Mate',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110038/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 335,
        // title: 'Peppa',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110039/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 336,
        // title: 'Gigi',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110040/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 337,
        // title: 'Blondie',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110041/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 338,
        // title: 'You Complete Me',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110042/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 339,
        // title: 'Bayou',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110043/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 340,
        // title: '#33',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110044/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 341,
        // title: 'As If',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110045/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 342,
        // title: 'Ginger',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110046/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 343,
        // title: 'Jr. Prom',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110047/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 344,
        // title: 'Foxy',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110048/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 345,
        // title: 'Rigoletto',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110049/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 346,
        // title: 'As You Wish',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110050/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 347,
        // title: 'Unicorn',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110051/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 348,
        // title: 'Stardust',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110052/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 349,
        // title: 'Kin',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110053/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 350,
        // title: 'Bright Eyes',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110054/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 351,
        // title: 'London',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110055/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 352,
        // title: 'On Wednesdays',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110056/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 353,
        // title: 'Stay Golden',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110057/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 354,
        // title: 'Bend and Snap',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110058/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 355,
        // title: 'Finn',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110059/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 356,
        // title: 'Crush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110060/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 357,
        // title: 'Rome',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110061/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 358,
        // title: 'Gilded',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110062/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 359,
        // title: 'Amethyst',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110063/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 360,
        // title: 'Emerald City',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110064/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 361,
        // title: 'Sabrina',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110065/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 362,
        // title: 'Leo',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110066/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 363,
        // title: 'Ivy League',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110067/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 364,
        // title: 'Filly',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110068/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 365,
        // title: 'Cupcake',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110069/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 366,
        // title: 'Lullaby',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110070/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 367,
        // title: 'Bird',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110071/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 368,
        // title: 'Cole',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110072/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 369,
        // title: 'Zion',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110073/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 370,
        // title: 'Trust',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110074/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 371,
        // title: 'Oak',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110075/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 372,
        // title: 'Pup',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110076/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 373,
        // title: 'Mi Hija',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110077/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 374,
        // title: 'Coco',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110078/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 375,
        // title: 'Midnight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110079/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 377,
        // title: 'Bubba',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110080/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 378,
        // title: 'Valencia',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110081/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 379,
        // title: 'Mama',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110082/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 380,
        // title: 'Basic',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110083/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 381,
        // title: 'Salem',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110084/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 445,
        // title: 'Reign',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110085/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 446,
        // title: 'Denim',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110086/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 447,
        // title: 'Duke',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110087/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 448,
        // title: 'Da Ba Dee',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110088/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 475,
        // title: 'Arabian Night',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110200/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 502,
        // title: 'Sapphire',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110201/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 525,
        // title: 'Spiced',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110202/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 526,
        // title: 'Tangled',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110203/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Eyeliner
      // ***************
      {
        id: 563,
        // title: 'Black Friday Eyeliner',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100650/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Makeup Bundles/Collections
      // ***************
      {
        id: 117,
        // title: 'Cayman Bundle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180201/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 119,
        // title: 'Powder Bundle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180203/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 123,
        // title: 'Lip + Cheek Collection',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180300/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 124,
        // title: ' Illuminator Collection',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180301/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 291,
        // title: 'Bella Bundle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180205/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 541,
        // title: 'Linen Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180800/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 542,
        // title: 'White Peach Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180801/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 543,
        // title: 'Aura Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180802/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 544,
        // title: 'Sunlit Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180803/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 545,
        // title: 'Candlelit Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180804/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 546,
        // title: 'June Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180805/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 547,
        // title: 'Amber Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180806/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 548,
        // title: 'Sandy Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180807/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 549,
        // title: 'Wheat Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180808/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 550,
        // title: 'Mango Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180809/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 551,
        // title: 'Goddess Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180810/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 552,
        // title: 'Icon Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180811/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 553,
        // title: 'Papaya Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180812/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 554,
        // title: 'Cinnamon Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180813/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 555,
        // title: 'Mirage Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180814/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 556,
        // title: 'Muse Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180815/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.makeup.out,
          low: stockLevelLimits.US.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Skincare
      // ***************
      {
        id: 106,
        // title: 'Stay Out There Setting Spray',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 107,
        // title: 'Stay Outside Setting Spray with SPF',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: true,
      },
      {
        id: 155,
        // title: 'Milk Moisturizing Creme',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180504/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 156,
        // title: 'Milk Toner',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180505/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 157,
        // title: 'Milk Cleanser',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180506/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 454,
        // title: 'Milk Masque',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180513/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 503,
        // title: 'Milk Cherry Lip Conditioner',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140121/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 504,
        // title: 'Milk Peppermint Lip Conditioner',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140122/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 505,
        // title: 'Tidal Microfiber Cloths',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140123/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },
      // * Skincare – Men
      {
        id: 436,
        // title: 'Creme - Tidal',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180510/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincareMen.out,
          low: stockLevelLimits.US.skincareMen.low,
        },
        discontinued: false,
      },
      {
        id: 437,
        // title: 'Toner - Tidal',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180511/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincareMen.out,
          low: stockLevelLimits.US.skincareMen.low,
        },
        discontinued: false,
      },
      {
        id: 438,
        // title: 'Cleanser - Tidal',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180512/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincareMen.out,
          low: stockLevelLimits.US.skincareMen.low,
        },
        discontinued: false,
      },
      {
        id: 439,
        // title: 'Tres Leches, Tidal Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180509/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincareMen.out,
          low: stockLevelLimits.US.skincareMen.low,
        },
        discontinued: false,
      },

      // ***************
      // * Skincare Bundles/Collections
      // ***************
      {
        id: 121,
        // title: 'Tres Leches- Skin Care System',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180503/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.skincare.out,
          low: stockLevelLimits.US.skincare.low,
        },
        discontinued: false,
      },

      // ***************
      // * Brushes
      // ***************
      {
        id: 98,
        // title: '30 Second HAC Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 99,
        // title: 'The Detail HAC Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 100,
        // title: 'Power Powder Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130002/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 101,
        // title: 'B Squared Bronzer and Blush Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130003/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 102,
        // title: 'I Shadow Everything Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130004/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 103,
        // title: 'The Multitasker Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130005/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 146,
        // title: 'Know Your Angles Brow Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130006/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: true,
      },
      {
        id: 443,
        // title: 'Best Blend Forever',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130007/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 444,
        // title: 'Blend and Tap',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130008/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 463,
        // title: 'Buffy Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130009/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 464,
        // title: 'Hotline Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130010/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },
      {
        id: 466,
        // title: 'Brush Collection',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180601/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.brush.out,
          low: stockLevelLimits.US.brush.low,
        },
        discontinued: false,
      },

      // ***************
      // * Tools/Other
      // ***************
      {
        id: 105,
        // title: 'IIID Perfector Sponge - Cotton Candy',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130101/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.tool.out,
          low: stockLevelLimits.US.tool.low,
        },
        discontinued: false,
      },
      {
        id: 322,
        // title: 'Microfiber Cloths',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140109/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.tool.out,
          low: stockLevelLimits.US.tool.low,
        },
        discontinued: false,
      },
      {
        id: 501,
        // title: 'Houdini Brush Cleanser ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140304/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.tool.out,
          low: stockLevelLimits.US.tool.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      // *
      // * New Releases
      // *
      // {
      //   id: 497,
      //   // title: '3oz Orla',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140500/0.jpg',
      //   stockLevel: {
      //     out: 300,
      //     low: 600,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 498,
      //   // title: '7oz Orla',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140501/0.jpg',
      //   stockLevel: {
      //     out: 300,
      //     low: 600,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 499,
      //   // title: '3oz Halo',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140600/0.jpg',
      //   stockLevel: {
      //     out: 300,
      //     low: 600,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 500,
      //   // title: '7oz Halo',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140601/0.jpg',
      //   stockLevel: {
      //     out: 300,
      //     low: 600,
      //   },
      //   discontinued: false,
      // },

      // ***************
      // * Compacts
      // ***************
      {
        id: 71,
        // title: 'Square Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 72,
        // title: 'Quad Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 73,
        // title: 'HAC Stack Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120002/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 74,
        // title: 'Mini Double Decker Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120003/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 75,
        // title: 'Double Decker Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120004/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 80,
        // title: 'Mini Double Decker Compact - Seaglass',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120102/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 81,
        // title: 'Double Decker Compact - Sea Glass',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120103/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 82,
        // title: 'Square Compact - La Jolla',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120200/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 85,
        // title: 'Mini Double Decker Compact - La Jolla',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120203/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 86,
        // title: 'Double Decker Compact - La Jolla',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120204/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 87,
        // title: 'Square Compact - Santorini',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120300/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 89,
        // title: 'HAC Stack Compact- Santorini',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120302/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 90,
        // title: 'Mini Double Decker Compact - Santorini',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120303/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 91,
        // title: 'Double Decker Compact - Santorini',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120304/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 92,
        // title: 'Square Compact - Sel de Mer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120400/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 94,
        // title: 'HAC Stack Compact - Sel de Mer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120402/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 95,
        // title: 'Mini Double Decker Compact - Sel de Mer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120403/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 96,
        // title: 'Double Decker Compact - Sel de Mer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120404/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 136,
        // title: 'Square Compact - Saltair',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120800/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 138,
        // title: 'HAC Stack Compact - Saltair',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120802/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 140,
        // title: 'Double Decker Compact - Saltair',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120804/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 144,
        // title: 'Mini Double Decker Compact - Fleur de Sel',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120903/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 145,
        // title: 'Double Decker Compact - Fleur de Sel',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120904/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 147,
        // title: 'Mini Double Decker Compact - Luxe',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120700/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 258,
        // title: 'HAC Stack Compact - Terra',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120012/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 259,
        // title: 'Mini Double Decker Compact - Terra',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120013/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 260,
        // title: 'Double Decker Compact - Terra',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120014/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 265,
        // title: 'Double Decker Compact - Ascent',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120024/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 268,
        // title: 'HAC Stack Compact - Icefall',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120032/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 269,
        // title: 'Mini Double Decker Compact - Icefall',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120033/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 270,
        // title: 'Double Decker Compact - Icefall',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120034/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 271,
        // title: 'Square Compact - Iridescent Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120040/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: false,
      },
      {
        id: 273,
        // title: 'HAC Stack Compact - Iridescent Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120042/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: false,
      },
      {
        id: 274,
        // title: 'Mini Double Decker Compact - Iridescent Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120043/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: false,
      },
      {
        id: 275,
        // title: 'Double Decker Compact - Iridescent Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120044/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: false,
      },
      {
        id: 278,
        // title: 'HAC Stack Compact - Celestial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120052/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 279,
        // title: 'Mini Double Decker Compact - Celestial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120053/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 280,
        // title: 'Double Decker Compact - Celestial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120054/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 281,
        // title: 'Square Compact - Imperial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120060/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 283,
        // title: 'HAC Stack Compact - Imperial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120062/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 284,
        // title: 'Mini Double Decker Compact - Imperial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120063/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 285,
        // title: 'Double Decker Compact - Imperial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120064/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 426,
        // title: 'Double Decker compact - Luxe',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120704/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 527,
        // title: 'Square Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120104/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 528,
        // title: 'Quad Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120105/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 529,
        // title: 'HAC Stack Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120106/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 530,
        // title: 'Mini Double Decker Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120107/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 531,
        // title: 'Double Decker Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120108/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 532,
        // title: 'Square Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120109/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 533,
        // title: 'Quad Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120110/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 534,
        // title: 'HAC Stack Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120111/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 535,
        // title: 'Mini Double Decker Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120112/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      {
        id: 536,
        // title: 'Double Decker Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120113/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: true,
      },
      // * Not selling?
      // {
      //   id: 537,
      //   // title: 'HAC Stack Compact - Golden Age',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120070/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.US.compact.out,
      //     low: stockLevelLimits.US.compact.low,
      //   },
      //   discontinued: true,
      // },
      {
        id: 539,
        // title: 'Mini Double Decker Compact - Crushed Velvet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120114/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: false,
      },
      {
        id: 540,
        // title: 'Double Decker Compact - Crushed Velvet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120115/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: false,
      },
      {
        id: 609,
        // title: 'Triple Decker Compact - Sistine',
        // image: 'https://s3.amazonaws.com/seintofficial.com/product-images/120149/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.compact.out,
          low: stockLevelLimits.US.compact.low,
        },
        discontinued: false,
      },

      // ***************
      // * Artist Palettes
      // ***************
      {
        id: 289,
        // title: 'Milk Chocolate Single Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120610/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 290,
        // title: 'Milk Chocolate Double Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120611/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 294,
        // title: 'Milk Chocolate Triple Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120612/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 295,
        // title: 'White Chocolate Single Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120620/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 296,
        // title: 'White Chocolate Double Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120621/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 297,
        // title: 'White Chocolate Triple Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120622/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 298,
        // title: 'Cherry Chocolate Single Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120630/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 300,
        // title: 'Cherry Chocolate Double Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120631/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 301,
        // title: 'Cherry Chocolate Triple Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120632/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistPalette.out,
          low: stockLevelLimits.US.artistPalette.low,
        },
        discontinued: false,
      },

      // ***************
      // * Clutches
      // ***************
      {
        id: 97,
        // title: 'Clutch Wallet - Mosaic Ivory',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120500/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.clutch.out,
          low: stockLevelLimits.US.clutch.low,
        },
        discontinued: false,
      },
      {
        id: 250,
        // title: ' Clutch Wallet Antique Navy',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120502/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.clutch.out,
          low: stockLevelLimits.US.clutch.low,
        },
        discontinued: false,
      },
      {
        id: 251,
        // title: 'Clutch Wallet Charcoal',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120503/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.clutch.out,
          low: stockLevelLimits.US.clutch.low,
        },
        discontinued: false,
      },
      {
        id: 252,
        // title: 'Clutch Wallet Dusty Lilac',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120504/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.clutch.out,
          low: stockLevelLimits.US.clutch.low,
        },
        discontinued: false,
      },

      // ***************
      // * Fragrances
      // ***************
      {
        id: 506,
        // title: 'Seint No. 1 Perfume',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100500/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.fragrance.out,
          low: stockLevelLimits.US.fragrance.low,
        },
        discontinued: false,
      },
      {
        id: 507,
        // title: 'Seint No. 2 Perfume',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100501/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.fragrance.out,
          low: stockLevelLimits.US.fragrance.low,
        },
        discontinued: false,
      },
      {
        id: 508,
        // title: 'Seint No. 3 Perfume',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100502/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.fragrance.out,
          low: stockLevelLimits.US.fragrance.low,
        },
        discontinued: false,
      },
      {
        id: 509,
        // title: 'Seint No. 4 Perfume',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100503/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.fragrance.out,
          low: stockLevelLimits.US.fragrance.low,
        },
        discontinued: false,
      },

      // ***************
      // * Artist Kits
      // ***************
      {
        id: 113,
        // title: 'Basic Artist Kit ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistKit.out,
          low: stockLevelLimits.US.artistKit.low,
        },
        discontinued: false,
      },
      {
        id: 115,
        // title: 'Pro Artist Kit',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.US.artistKit.out,
          low: stockLevelLimits.US.artistKit.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },

      // ***************
      // * Unknown/Not Products
      // ***************
      {
        id: 1,
        // title: 'Test Item ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/x001/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 3,
        // title: 'Seint Color Black ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/zz002/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 5,
        // title: 'Green Eye Shadow ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/zz004/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 112,
        // title: 'NAME OF KIT (ENROLLMENT PACK) PARENT DYNAMIC',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/zzd001/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 114,
        // title: 'Back Office Access',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/w1/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 127,
        // title: 'TestSku',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/testsku/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 129,
        // title: 'R1',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/r1/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },

      {
        id: 229,
        // title: 'Temporary Tattoos (20 ct.)',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190000/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 230,
        // title: 'Discount Test',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/d1/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 232,
        // title: 'Clutch Replacement Chain',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120501/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 234,
        // title: 'Setting Spray Replacement Nozzle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140002/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 235,
        // title: 'Tres Leches Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140106/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 236,
        // title: 'Brush Cleaner Replacement Nozzle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140301/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 237,
        // title: 'Face Card',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190100/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 238,
        // title: 'Eyeshadow Card',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190101/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 303,
        // title: 'Toner Replacement Nozzle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140111/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 304,
        // title: 'test item $0',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/test-item-0/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 402,
        // title: 'Test Kit',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/tx1/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 428,
        // title: 'Holiday Foot Cream Socks',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140118/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 433,
        // title: 'Toner w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140104/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 434,
        // title: 'Cleanser w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140105/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 435,
        // title: 'Milk w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140103/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 440,
        // title: 'MM Milk w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140114/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 441,
        // title: 'MM Toner w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140115/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 442,
        // title: 'MM Cleanser w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140116/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 455,
        // title: 'Milk Masque Exfoliator (w/o Card)',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140108/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 456,
        // title: 'Masque Card',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140119/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 457,
        // title: 'Coffe Table Catalog',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190103/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 458,
        // title: 'Distributor Catalog',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190104/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 459,
        // title: 'Host Brochure',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190105/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 460,
        // title: 'Opportunity Brochure',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190106/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 461,
        // title: 'Postcard',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190107/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 462,
        // title: 'Welcome Letter',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190108/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 465,
        // title: 'Tres Leches Pamphlet (Small)',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140110/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 467,
        // title: 'Milk Moisturizing Cream',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180504t/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
    ],
  },
  // ***********************************
  // * Canada
  // ***********************************
  {
    id: 2,
    name: 'Canada',
    products: [
      // ***************
      // * Highlight
      // ***************
      {
        id: 7,
        // title: 'Moonlit Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 8,
        // title: 'White Peach Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 9,
        // title: 'Aura Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100002/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 10,
        // title: 'Sunlit Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100003/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 11,
        // title: 'Amber Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100004/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 13,
        // title: 'Wheat Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100005/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 14,
        // title: 'Mango Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100006/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 15,
        // title: 'Cinnamon Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100007/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 16,
        // title: 'Goddess Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100008/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 17,
        // title: 'Papaya Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100009/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 18,
        // title: 'June Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100010/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 249,
        // title: 'Linen Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100011/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 324,
        // title: 'Sandy Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100012/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 325,
        // title: 'Candlelit Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100013/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 489,
        // title: 'Mirage Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100014/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 490,
        // title: 'Muse Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100015/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 491,
        // title: 'Icon Highlight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100016/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Contour
      // ***************
      {
        id: 19,
        // title: 'Walnut Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100100/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 20,
        // title: 'Stone Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100101/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 21,
        // title: 'Ash Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100102/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 22,
        // title: 'Shadow Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100103/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 23,
        // title: 'Indigo Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100104/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 24,
        // title: 'Olive Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100105/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 125,
        // title: 'Aspen Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100106/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 248,
        // title: 'Astoria Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100107/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 286,
        // title: 'Cola Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100108/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 492,
        // title: 'Henna Contour',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100109/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Lip + Cheek
      // ***************
      {
        id: 25,
        // title: 'Pink Grapefruit Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100200/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 26,
        // title: 'Dahlia Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100201/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 27,
        // title: 'Ruby Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100202/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 28,
        // title: 'Desert Sunset Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100203/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 29,
        // title: 'Baby Watermelon Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100204/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 30,
        // title: 'Black Cherry Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100205/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 31,
        // title: 'Petal Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100206/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 32,
        // title: 'Nude Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100207/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 33,
        // title: 'Frenchie Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100208/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 34,
        // title: 'Bare Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100209/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 35,
        // title: 'Sandstone Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100210/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 36,
        // title: 'Hollywood Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100211/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 37,
        // title: 'Ever Red Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100212/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 245,
        // title: 'Scarlet Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100213/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 246,
        // title: 'Plum Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100214/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 382,
        // title: 'Tropicana Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100215/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 383,
        // title: 'Ballerina Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100216/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 384,
        // title: 'Royal Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100217/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 385,
        // title: 'Lolli Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100218/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 386,
        // title: 'Hopelessly Devoted Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100219/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 387,
        // title: 'Sadie Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100220/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 388,
        // title: 'Sunshine State Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100221/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 389,
        // title: 'Summer Love Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100222/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 493,
        // title: 'Lovespell Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100223/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 494,
        // title: 'Saffron Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100224/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 495,
        // title: 'Cindy Lip + Cheek ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100225/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      // *
      // * New Releases
      // *
      // {
      //   id: 515,
      //   // title: 'La Cienega Blvd Lip + Cheek ',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100229/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 524,
      //   // title: 'Suede Lip Liner ',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100600/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 538,
      //   // title: 'Royal Lipstick Tube',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100601/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: true,
      // },

      // ***************
      // * Illuminator
      // ***************
      {
        id: 38,
        // title: 'Pearl Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100300/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 39,
        // title: 'Honey Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100301/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 40,
        // title: 'Rose Gold Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100302/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 244,
        // title: 'Glow Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100304/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 390,
        // title: 'Angel Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100305/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 391,
        // title: 'Photoshop Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100403/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 392,
        // title: 'Goldie Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100404/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 393,
        // title: 'Georgia Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100405/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 394,
        // title: 'Glamazing Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100406/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 395,
        // title: 'Starlet Illuminator',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100407/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      // *
      // * New Releases
      // *
      // {
      //   id: 518,
      //   // title: 'Prism Illuminator',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100306/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 519,
      //   // title: 'Sugar Illuminator',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100307/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: false,
      // },

      // ***************
      // * Setting Powder
      // ***************
      {
        id: 42,
        // title: 'Vanilla Dust Setting Powder',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100401/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },

      // ***************
      // * Bronzer
      // ***************
      {
        id: 41,
        // title: 'Cayman Bronzer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100400/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 126,
        // title: 'Bella Bronzer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100402/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      // *
      // * New Releases
      // *
      // {
      //   id: 520,
      //   // title: 'Barcelona Bronzer',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100410/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 521,
      //   // title: 'Tiki Cream Bronzer ',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100411/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 522,
      //   // title: 'Heatwaves Cream Bronzer',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100412/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 523,
      //   // title: 'Cabana Cream Bronzer',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100413/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.makeup.out,
      //     low: stockLevelLimits.Canada.makeup.low,
      //   },
      //   discontinued: false,
      // },

      // ***************
      // * Eyeshadow
      // ***************
      {
        id: 327,
        // title: 'Lala land',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110031/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 328,
        // title: 'Graceland',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110032/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 329,
        // title: 'Dollywood',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110033/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 330,
        // title: 'Shenandoah',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110034/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 331,
        // title: 'Tawanda',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110035/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 332,
        // title: 'Glass Slipper',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110036/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 333,
        // title: 'Gold Digger',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110037/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 334,
        // title: 'Soul Mate',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110038/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 335,
        // title: 'Peppa',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110039/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 336,
        // title: 'Gigi',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110040/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 337,
        // title: 'Blondie',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110041/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 338,
        // title: 'You Complete Me',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110042/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 339,
        // title: 'Bayou',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110043/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 340,
        // title: '#33',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110044/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 341,
        // title: 'As If',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110045/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 342,
        // title: 'Ginger',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110046/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 343,
        // title: 'Jr. Prom',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110047/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 344,
        // title: 'Foxy',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110048/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 345,
        // title: 'Rigoletto',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110049/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 346,
        // title: 'As You Wish',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110050/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 347,
        // title: 'Unicorn',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110051/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 348,
        // title: 'Stardust',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110052/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 349,
        // title: 'Kin',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110053/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 350,
        // title: 'Bright Eyes',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110054/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 351,
        // title: 'London',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110055/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 352,
        // title: 'On Wednesdays',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110056/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 353,
        // title: 'Stay Golden',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110057/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 354,
        // title: 'Bend and Snap',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110058/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 355,
        // title: 'Finn',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110059/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 356,
        // title: 'Crush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110060/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 357,
        // title: 'Rome',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110061/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 358,
        // title: 'Gilded',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110062/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 359,
        // title: 'Amethyst',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110063/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 360,
        // title: 'Emerald City',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110064/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 361,
        // title: 'Sabrina',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110065/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 362,
        // title: 'Leo',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110066/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 363,
        // title: 'Ivy League',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110067/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 364,
        // title: 'Filly',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110068/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 365,
        // title: 'Cupcake',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110069/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 366,
        // title: 'Lullaby',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110070/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 367,
        // title: 'Bird',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110071/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 368,
        // title: 'Cole',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110072/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 369,
        // title: 'Zion',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110073/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 370,
        // title: 'Trust',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110074/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 371,
        // title: 'Oak',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110075/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 372,
        // title: 'Pup',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110076/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 373,
        // title: 'Mi Hija',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110077/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 374,
        // title: 'Coco',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110078/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 375,
        // title: 'Midnight',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110079/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 377,
        // title: 'Bubba',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110080/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 378,
        // title: 'Valencia',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110081/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 379,
        // title: 'Mama',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110082/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 380,
        // title: 'Basic',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110083/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 381,
        // title: 'Salem',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110084/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 445,
        // title: 'Reign',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110085/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 446,
        // title: 'Denim',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110086/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 447,
        // title: 'Duke',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110087/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 448,
        // title: 'Da Ba Dee',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110088/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 475,
        // title: 'Arabian Night',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110200/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 502,
        // title: 'Sapphire',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110201/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 525,
        // title: 'Spiced',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110202/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 526,
        // title: 'Tangled',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/110203/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Eyeliner
      // ***************
      {
        id: 563,
        // title: 'Black Friday Eyeliner',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100650/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Makeup Bundles/Collections
      // ***************
      {
        id: 117,
        // title: 'Cayman Bundle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180201/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 119,
        // title: 'Powder Bundle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180203/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 123,
        // title: 'Lip + Cheek Collection',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180300/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 124,
        // title: ' Illuminator Collection',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180301/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 291,
        // title: 'Bella Bundle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180205/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 541,
        // title: 'Linen Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180800/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 542,
        // title: 'White Peach Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180801/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 543,
        // title: 'Aura Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180802/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 544,
        // title: 'Sunlit Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180803/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 545,
        // title: 'Candlelit Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180804/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 546,
        // title: 'June Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180805/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 547,
        // title: 'Amber Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180806/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 548,
        // title: 'Sandy Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180807/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 549,
        // title: 'Wheat Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180808/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 550,
        // title: 'Mango Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180809/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 551,
        // title: 'Goddess Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180810/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 552,
        // title: 'Icon Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180811/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 553,
        // title: 'Papaya Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180812/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 554,
        // title: 'Cinnamon Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180813/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 555,
        // title: 'Mirage Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180814/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },
      {
        id: 556,
        // title: 'Muse Winter Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180815/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.makeup.out,
          low: stockLevelLimits.Canada.makeup.low,
        },
        discontinued: false,
      },

      // ***************
      // * Skincare
      // ***************
      {
        id: 106,
        // title: 'Stay Out There Setting Spray',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 155,
        // title: 'Milk Moisturizing Creme',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180504/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 156,
        // title: 'Milk Toner',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180505/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 157,
        // title: 'Milk Cleanser',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180506/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 454,
        // title: 'Milk Masque',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180513/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
      },
      {
        id: 503,
        // title: 'Milk Cherry Lip Conditioner',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140121/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 504,
        // title: 'Milk Peppermint Lip Conditioner',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140122/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 505,
        // title: 'Tidal Microfiber Cloths',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140123/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
      },
      // * Skincare – Men
      {
        id: 436,
        // title: 'Creme - Tidal',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180510/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincareMen.out,
          low: stockLevelLimits.Canada.skincareMen.low,
        },
        discontinued: false,
      },
      {
        id: 437,
        // title: 'Toner - Tidal',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180511/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincareMen.out,
          low: stockLevelLimits.Canada.skincareMen.low,
        },
        discontinued: false,
      },
      {
        id: 438,
        // title: 'Cleanser - Tidal',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180512/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincareMen.out,
          low: stockLevelLimits.Canada.skincareMen.low,
        },
        discontinued: false,
      },
      {
        id: 439,
        // title: 'Tres Leches, Tidal Collection ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180509/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincareMen.out,
          low: stockLevelLimits.Canada.skincareMen.low,
        },
        discontinued: false,
      },

      // ***************
      // * Skincare Bundles/Collections
      // ***************
      {
        id: 121,
        // title: 'Tres Leches- Skin Care System',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180503/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.skincare.out,
          low: stockLevelLimits.Canada.skincare.low,
        },
        discontinued: false,
      },

      // ***************
      // * Brushes
      // ***************
      {
        id: 98,
        // title: '30 Second HAC Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 99,
        // title: 'The Detail HAC Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },
      {
        id: 100,
        // title: 'Power Powder Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130002/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },
      {
        id: 101,
        // title: 'B Squared Bronzer and Blush Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130003/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },
      {
        id: 102,
        // title: 'I Shadow Everything Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130004/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },
      {
        id: 103,
        // title: 'The Multitasker Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130005/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },
      {
        id: 146,
        // title: 'Know Your Angles Brow Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130006/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },
      {
        id: 443,
        // title: 'Best Blend Forever',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130007/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },
      {
        id: 444,
        // title: 'Blend and Tap',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130008/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },
      {
        id: 463,
        // title: 'Buffy Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130009/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 464,
        // title: 'Hotline Brush',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130010/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 466,
        // title: 'Brush Collection',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180601/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.brush.out,
          low: stockLevelLimits.Canada.brush.low,
        },
        discontinued: false,
      },

      // ***************
      // * Tools/Other
      // ***************
      {
        id: 105,
        // title: 'IIID Perfector Sponge - Cotton Candy',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/130101/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.tool.out,
          low: stockLevelLimits.Canada.tool.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      {
        id: 322,
        // title: 'Microfiber Cloths',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140109/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.tool.out,
          low: stockLevelLimits.Canada.tool.low,
        },
        discontinued: false,
      },
      {
        id: 501,
        // title: 'Houdini Brush Cleanser ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140304/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.tool.out,
          low: stockLevelLimits.Canada.tool.low,
        },
        discontinued: false,
        //restockETA: 'early November',
      },
      // *
      // * New Releases
      // *
      // {
      //   id: 497,
      //   // title: '3oz Orla',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140500/0.jpg',
      //   stockLevel: {
      //     out: 300,
      //     low: 600,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 498,
      //   // title: '7oz Orla',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140501/0.jpg',
      //   stockLevel: {
      //     out: 300,
      //     low: 600,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 499,
      //   // title: '3oz Halo',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140600/0.jpg',
      //   stockLevel: {
      //     out: 300,
      //     low: 600,
      //   },
      //   discontinued: false,
      // },
      // {
      //   id: 500,
      //   // title: '7oz Halo',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140601/0.jpg',
      //   stockLevel: {
      //     out: 300,
      //     low: 600,
      //   },
      //   discontinued: false,
      // },

      // ***************
      // * Compacts
      // ***************
      {
        id: 71,
        // title: 'Square Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 72,
        // title: 'Quad Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 73,
        // title: 'HAC Stack Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120002/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 74,
        // title: 'Mini Double Decker Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120003/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 75,
        // title: 'Double Decker Compact - Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120004/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 80,
        // title: 'Mini Double Decker Compact - Seaglass',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120102/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 81,
        // title: 'Double Decker Compact - Sea Glass',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120103/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 82,
        // title: 'Square Compact - La Jolla',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120200/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 85,
        // title: 'Mini Double Decker Compact - La Jolla',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120203/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 86,
        // title: 'Double Decker Compact - La Jolla',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120204/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 87,
        // title: 'Square Compact - Santorini',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120300/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 89,
        // title: 'HAC Stack Compact- Santorini',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120302/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 90,
        // title: 'Mini Double Decker Compact - Santorini',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120303/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 91,
        // title: 'Double Decker Compact - Santorini',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120304/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 92,
        // title: 'Square Compact - Sel de Mer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120400/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 94,
        // title: 'HAC Stack Compact - Sel de Mer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120402/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 95,
        // title: 'Mini Double Decker Compact - Sel de Mer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120403/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 96,
        // title: 'Double Decker Compact - Sel de Mer',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120404/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 136,
        // title: 'Square Compact - Saltair',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120800/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 138,
        // title: 'HAC Stack Compact - Saltair',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120802/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 140,
        // title: 'Double Decker Compact - Saltair',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120804/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 144,
        // title: 'Mini Double Decker Compact - Fleur de Sel',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120903/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 145,
        // title: 'Double Decker Compact - Fleur de Sel',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120904/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 147,
        // title: 'Mini Double Decker Compact - Luxe',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120700/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 258,
        // title: 'HAC Stack Compact - Terra',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120012/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 259,
        // title: 'Mini Double Decker Compact - Terra',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120013/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 260,
        // title: 'Double Decker Compact - Terra',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120014/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 265,
        // title: 'Double Decker Compact - Ascent',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120024/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 268,
        // title: 'HAC Stack Compact - Icefall',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120032/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 269,
        // title: 'Mini Double Decker Compact - Icefall',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120033/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 270,
        // title: 'Double Decker Compact - Icefall',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120034/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 271,
        // title: 'Square Compact - Iridescent Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120040/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: false,
      },
      {
        id: 273,
        // title: 'HAC Stack Compact - Iridescent Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120042/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: false,
      },
      {
        id: 274,
        // title: 'Mini Double Decker Compact - Iridescent Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120043/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: false,
      },
      {
        id: 275,
        // title: 'Double Decker Compact - Iridescent Marble',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120044/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: false,
      },
      {
        id: 278,
        // title: 'HAC Stack Compact - Celestial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120052/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 279,
        // title: 'Mini Double Decker Compact - Celestial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120053/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 280,
        // title: 'Double Decker Compact - Celestial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120054/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 281,
        // title: 'Square Compact - Imperial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120060/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 283,
        // title: 'HAC Stack Compact - Imperial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120062/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 284,
        // title: 'Mini Double Decker Compact - Imperial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120063/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 285,
        // title: 'Double Decker Compact - Imperial',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120064/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 426,
        // title: 'Double Decker compact - Luxe',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120704/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 527,
        // title: 'Square Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120104/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 528,
        // title: 'Quad Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120105/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 529,
        // title: 'HAC Stack Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120106/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 530,
        // title: 'Mini Double Decker Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120107/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 531,
        // title: 'Double Decker Compact - Geode',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120108/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 532,
        // title: 'Square Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120109/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 533,
        // title: 'Quad Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120110/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 534,
        // title: 'HAC Stack Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120111/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 535,
        // title: 'Mini Double Decker Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120112/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 536,
        // title: 'Double Decker Compact - Cracked Earth',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120113/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      // * Not selling?
      // {
      //   id: 537,
      //   // title: 'HAC Stack Compact - Golden Age',
      //   // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120070/0.jpg',
      //   stockLevel: {
      //     out: stockLevelLimits.Canada.compact.out,
      //     low: stockLevelLimits.Canada.compact.low,
      //   },
      //   discontinued: true,
      // },
      {
        id: 539,
        // title: 'Mini Double Decker Compact - Crushed Velvet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120114/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },
      {
        id: 540,
        // title: 'Double Decker Compact - Crushed Velvet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120115/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.compact.out,
          low: stockLevelLimits.Canada.compact.low,
        },
        discontinued: true,
      },

      // ***************
      // * Artist Palettes
      // ***************
      {
        id: 289,
        // title: 'Milk Chocolate Single Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120610/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 290,
        // title: 'Milk Chocolate Double Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120611/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 294,
        // title: 'Milk Chocolate Triple Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120612/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 295,
        // title: 'White Chocolate Single Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120620/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 296,
        // title: 'White Chocolate Double Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120621/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 297,
        // title: 'White Chocolate Triple Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120622/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 298,
        // title: 'Cherry Chocolate Single Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120630/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 300,
        // title: 'Cherry Chocolate Double Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120631/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },
      {
        id: 301,
        // title: 'Cherry Chocolate Triple Layer Artist Pro Palette',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120632/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistPalette.out,
          low: stockLevelLimits.Canada.artistPalette.low,
        },
        discontinued: false,
      },

      // ***************
      // * Clutches
      // ***************
      {
        id: 97,
        // title: 'Clutch Wallet - Mosaic Ivory',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120500/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.clutch.out,
          low: stockLevelLimits.Canada.clutch.low,
        },
        discontinued: false,
      },
      {
        id: 250,
        // title: ' Clutch Wallet Antique Navy',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120502/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.clutch.out,
          low: stockLevelLimits.Canada.clutch.low,
        },
        discontinued: false,
      },
      {
        id: 251,
        // title: 'Clutch Wallet Charcoal',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120503/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.clutch.out,
          low: stockLevelLimits.Canada.clutch.low,
        },
        discontinued: false,
      },
      {
        id: 252,
        // title: 'Clutch Wallet Dusty Lilac',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120504/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.clutch.out,
          low: stockLevelLimits.Canada.clutch.low,
        },
        discontinued: false,
      },

      // ***************
      // * Fragrances
      // ***************
      {
        id: 506,
        // title: 'Seint No. 1 Perfume',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100500/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.fragrance.out,
          low: stockLevelLimits.Canada.fragrance.low,
        },
        discontinued: false,
      },
      {
        id: 507,
        // title: 'Seint No. 2 Perfume',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100501/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.fragrance.out,
          low: stockLevelLimits.Canada.fragrance.low,
        },
        discontinued: false,
      },
      {
        id: 508,
        // title: 'Seint No. 3 Perfume',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100502/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.fragrance.out,
          low: stockLevelLimits.Canada.fragrance.low,
        },
        discontinued: false,
      },
      {
        id: 509,
        // title: 'Seint No. 4 Perfume',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/100503/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.fragrance.out,
          low: stockLevelLimits.Canada.fragrance.low,
        },
        discontinued: false,
      },

      // ***************
      // * Artist Kits
      // ***************
      {
        id: 113,
        // title: 'Basic Artist Kit ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180000/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistKit.out,
          low: stockLevelLimits.Canada.artistKit.low,
        },
        discontinued: false,
      },
      {
        id: 115,
        // title: 'Pro Artist Kit',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180001/0.jpg',
        stockLevel: {
          out: stockLevelLimits.Canada.artistKit.out,
          low: stockLevelLimits.Canada.artistKit.low,
        },
        discontinued: false,
      },

      // ***************
      // * Unknown/Not Products
      // ***************
      {
        id: 1,
        // title: 'Test Item ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/x001/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 3,
        // title: 'Seint Color Black ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/zz002/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 5,
        // title: 'Green Eye Shadow ',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/zz004/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 112,
        // title: 'NAME OF KIT (ENROLLMENT PACK) PARENT DYNAMIC',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/zzd001/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 114,
        // title: 'Back Office Access',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/w1/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 127,
        // title: 'TestSku',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/testsku/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 129,
        // title: 'R1',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/r1/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },

      {
        id: 229,
        // title: 'Temporary Tattoos (20 ct.)',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190000/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 230,
        // title: 'Discount Test',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/d1/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 232,
        // title: 'Clutch Replacement Chain',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/120501/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 234,
        // title: 'Setting Spray Replacement Nozzle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140002/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 235,
        // title: 'Tres Leches Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140106/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 236,
        // title: 'Brush Cleaner Replacement Nozzle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140301/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 237,
        // title: 'Face Card',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190100/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 238,
        // title: 'Eyeshadow Card',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190101/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 303,
        // title: 'Toner Replacement Nozzle',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140111/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 304,
        // title: 'test item $0',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/test-item-0/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 402,
        // title: 'Test Kit',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/tx1/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 428,
        // title: 'Holiday Foot Cream Socks',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140118/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 433,
        // title: 'Toner w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140104/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 434,
        // title: 'Cleanser w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140105/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 435,
        // title: 'Milk w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140103/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 440,
        // title: 'MM Milk w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140114/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 441,
        // title: 'MM Toner w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140115/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 442,
        // title: 'MM Cleanser w/o Pamphlet',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140116/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 455,
        // title: 'Milk Masque Exfoliator (w/o Card)',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140108/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 456,
        // title: 'Masque Card',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140119/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 457,
        // title: 'Coffe Table Catalog',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190103/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 458,
        // title: 'Distributor Catalog',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190104/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 459,
        // title: 'Host Brochure',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190105/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 460,
        // title: 'Opportunity Brochure',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190106/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 461,
        // title: 'Postcard',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190107/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 462,
        // title: 'Welcome Letter',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/190108/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 465,
        // title: 'Tres Leches Pamphlet (Small)',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/140110/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
      {
        id: 467,
        // title: 'Milk Moisturizing Cream',
        // image: 'https://s3.us-east-2.amazonaws.com/seintofficial.com/product-images/180504t/0.jpg',
        stockLevel: {
          out: -9999,
          low: -9999,
        },
        discontinued: false,
      },
    ],
  },
]

const getProductsByWarehouseId = warehouseId => {
  return warehouseProducts.find(wh => wh.id === warehouseId).products
}

export { getProductsByWarehouseId }
