import React from 'react'
import styled from 'styled-components'

/*
 * The <Container> component is a <div> element used for containing children within a max-width which is
 * set to the `theme.siteMaxWidth`
 */

/**
 * @example
 * <Container className="wrap"> // a <ul> element
 *   ...
 * </Container>
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${props => props.theme.siteMaxWidth};
`

const Container = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>
}

export default Container
