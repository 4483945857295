/* eslint-disable react/display-name */
import React from 'react'
import ArtistReunionLayout from './ArtistReunionLayout'
import EventsLayout from './EventsLayout'
import FoundationLayout from './FoundationLayout'
import IncentiveTripLayout from './IncentiveTripLayout'
import InventoryLayout from './InventoryLayout'
import Layout from './Layout'
import NewsLayout from './NewsLayout'
import RecognitionLayout from './RecognitionLayout'
import RewardsLayout from './RewardsLayout'
import SupportLayout from './SupportLayout'
import TrainingLayout from './TrainingLayout'
import TrainingSubLayout from './TrainingSubLayout'
import VirtualMeetingLayout from './VirtualMeetingLayout'

const LayoutTemplate = ({ location, children, pageContext }) => {
  if (pageContext.layout === 'news') {
    return (
      <NewsLayout pageName="News" location={location}>
        {children}
      </NewsLayout>
    )
  }
  if (pageContext.layout === 'incentive-trip') {
    return (
      <IncentiveTripLayout pageName="Incentive Trip" location={location}>
        {children}
      </IncentiveTripLayout>
    )
  }
  if (pageContext.layout === 'artist-reunion') {
    return (
      <ArtistReunionLayout pageName="Artist Reunion" location={location}>
        {children}
      </ArtistReunionLayout>
    )
  }
  if (pageContext.layout === 'virtual-meeting') {
    return (
      <VirtualMeetingLayout
        pageName="Virtual Meeting"
        location={location}
      >
        {children}
      </VirtualMeetingLayout>
    )
  }
  if (pageContext.layout === 'events') {
    return (
      <EventsLayout pageName="Events" location={location}>
        {children}
      </EventsLayout>
    )
  }
  if (pageContext.layout === 'inventory') {
    return (
      <InventoryLayout pageName="Inventory" location={location}>
        {children}
      </InventoryLayout>
    )
  }
  if (pageContext.layout === 'training') {
    return (
      <TrainingLayout pageName="Training" location={location}>
        <TrainingSubLayout>{children}</TrainingSubLayout>
      </TrainingLayout>
    )
  }
  if (pageContext.layout === 'support') {
    return (
      <SupportLayout pageName="Support" location={location}>
        {children}
      </SupportLayout>
    )
  }
  if (pageContext.layout === 'foundation') {
    return (
      <FoundationLayout pageName="Foundation" location={location}>
        {children}
      </FoundationLayout>
    )
  }
  if (pageContext.layout === 'rewards') {
    return (
      <RewardsLayout pageName="Rewards Program" location={location}>
        {children}
      </RewardsLayout>
    )
  }
  if (pageContext.layout === 'recognition') {
    return (
      <RecognitionLayout pageName="Recognition" location={location}>
        {children}
      </RecognitionLayout>
    )
  }
  if (pageContext.layout === 'top-artist-profile') {
    return (
      <Layout pageName="Top Artist Profile" location={location}>
        {children}
      </Layout>
    )
  }
  if (pageContext.layout === 'article') {
    return (
      <Layout pageName="Article" location={location}>
        {children}
      </Layout>
    )
  }
  if (pageContext.layout === 'home') {
    return (
      <Layout pageName="Home" location={location}>
        {children}
      </Layout>
    )
  } else {
    return <>{children}</>
  }
}
export default LayoutTemplate
