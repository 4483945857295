const theme = {
  siteMaxWidth: '1180px', // 1140 + 20 padding-left + 20 padding-right
  siteXPaddingSm: '16px',
  siteXPaddingLg: '20px',
  responsive: {
    xs: '320px',
    underSmall: '374px',
    small: '375px',
    underMedium: '767px',
    medium: '768px',
    overMedium: '769px',
    large: '1024px',
    underLarge: '1023px',
    xLarge: '1440px',
  },
  containerWidths: {
    xs: '375px',
    small: '550px',
    smallMedium: '650px',
    medium: '750px',
    mediumLarge: '850px',
    large: '1080px',
  },
  colors: {
    offWhite: '#F6F7F7',
    nearlyWhite: '#F4F6F6',
    lightBorder: '#E5E5E5',
    lighterGray: '#EEEEEE',
    lightGray: '#A2A2A2',
    disabledButtonBg: '#CCCCCC',
    unchecked: '#CCCCCC',
    placeholderText: '#999999',
    grayText: '#888888',
    grayTextAlt: '#484848',
    mediumBorder: '#CCCCCC',
    buttonBorder: '#000000',
    darkText: '#000000',
    darkBg: '#000000',
    primary: '#000000',
    accentReunion: '#000000',
    accentIncentiveTrip: '#000000',
    accentVirtualMeeting: '#000000',
    errorRed: '#B3554D',
    webkitTapHighlight: 'rgba(0, 0, 0, 0.15)',
  },
  typography: {
    emoji:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    sansSerif:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    serif:
      '"Butler Light", "Butler Light remote", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    serifDisplay:
      '"Butler Light", "Butler Light remote", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    h: {
      mtSm: '25px',
      mbSm: '10px',
    },
    h2: {
      fsSm: '22px',
      lhSm: '26px',
    },
    h3: {
      fsSm: '18px',
      lhSm: '22px',
    },
    h4: {
      fsSm: '16px',
      lhSm: '20px',
    },
    h5: {
      fsSm: '14px',
      lhSm: '18px',
    },
    body: {
      fsSm: '15px',
      lhSm: '24px',
      fsLg: '15px',
      lhLg: '24px',
    },
    excerpt: {
      lhSm: '24px',
      lhLg: '24px',
    },
    displayFw: '400',
    headlines: {
      hero: {
        fStyle: 'italic',
        fsSm: '43px',
        lhSm: '50px',
        fsLg: '100px',
        lhLg: '100px',
      },
      large: {
        width: '750px',
        mtSm: '14px',
        mbSm: '17px',
        fsSm: '32px',
        lhSm: '36px',
        mtLg: '15px',
        mbLg: '25px',
        fsLg: '55px',
        lhLg: '58px',
      },
      medium: {
        mtSm: '6px',
        mbSm: '15px',
        fsSm: '23px',
        lhSm: '28px',
        mtLg: '6px',
        mbLg: '15px',
        fsLg: '43px',
        lhLg: '47px',
      },
      small: {
        mtSm: '0',
        mbSm: '10px',
        fsSm: '22px',
        lhSm: '26px',
        mtLg: '6px',
        mbLg: '20px',
        fsLg: '30px',
        lhLg: '34px',
      },
      xs: {
        mtSm: '0',
        mbSm: '15px',
        fsSm: '18px',
        lhSm: '22px',
        mtLg: '0',
        mbLg: '15px',
        fsLg: '24px',
        lhLg: '30px',
      },
      withLines: {
        mtSm: '0',
        mbSm: '25px',
        mtLg: '0',
        mbLg: '40px',
        minLineLength: '30px',
        minLineLengthLg: '60px',
      },
      reunion: {
        fStyle: 'italic',
        fsSm: '24px',
        lhSm: '22px',
        fsLg: '30px',
        lhLg: '28px',
      },
      // incentiveTrip: {
      //   fw: '600',
      //   fsSm: '17px',
      //   lhSm: '21px',
      //   fsLg: '17px',
      //   lhLg: '21px',
      // },
      // virtualMeeting: {
      //   fw: '600',
      //   fsSm: '17px',
      //   lhSm: '21px',
      //   fsLg: '17px',
      //   lhLg: '21px',
      // },
      intro: {
        width: '750px',
        fwSm: '400',
        // mtSm: '15px',
        mbSm: '18px',
        fsSm: '17px',
        lhSm: '26px',
        // mtLg: '15px',
        // mbLg: '20px',
        fwLg: '400',
        fsLg: '20px',
        lhLg: '30px',
      },
    },
    sectionTitle: {
      mbSm: '10px',
      fw: '600',
      fsSm: '20px',
      lhSm: '23px',
      lsSm: '1px',
      mbLg: '15px',
      fsLg: '26px',
      lhLg: '36px',
      lsLg: '1.5px',
      secondary: {
        fw: '600',
        mtSm: '30px',
        mbSm: '20px',
        fsSm: '15px',
        lhSm: '24px',
        mtLg: '60px',
        fsLg: '20px',
        lhLg: '28px',
      },
      serif: {
        // mtSm: '30px',
        // mbSm: '20px',
        fsSm: '24px',
        lhSm: '30px',
        // mtLg: '60px',
        fsLg: '32px',
        lhLg: '32px',
      },
    },
    sectionLabel: {
      mtSm: '30px',
      mbSm: '15px',
      fw: '600',
      fsSm: '15px',
      lhSm: '24px',
      mtLg: '30px',
      mbLg: '25px',
      fsLg: '15px',
      lhLg: '27px',
    },
    label: {
      fw: '600',
      fsSm: '11px',
      lhSm: '11px',
      lsSm: '1px',
      fsLg: '12px',
      lhLg: '12px',
      lsLg: '1px',
    },
    detail: {
      fsSm: '12px',
      lhSm: '12px',
    },
    caption: {
      fsSm: '12px',
      lhSm: '18px',
    },
    blockquote: {
      fsSm: '26px',
      lhSm: '34px',
      fsLg: '26px',
      lhLg: '34px',
    },
  },
  navMenu: {
    heightSm: '55px',
    heightLg: '88px',
  },
  siteBanner: {
    timing: '500ms',
    delay: '120ms',
    heightSm: '55px',
    fsSm: '15px',
    lhSm: '15px',
    heightLg: '70px',
  },
  stickyNav: {
    fsSm: '13px',
    lhSm: '13px',
    heightSm: '43px',
    fsLg: '15px',
    lhLg: '15px',
    heightLg: '75px',
  },
  modal: {
    boxShadow: '0 30px 40px 0 rgba(0,0,0,0.2), 0 2px 12px 0 rgba(0,0,0,0.08)',
    padding: '25px 16px 30px',
  },
  section: {
    topSm: '30px',
    bottomSm: '30px',
    topMd: '55px',
    bottomMd: '55px',
    topLg: '80px',
    bottomLg: '80px',
  },
  input: {
    minHSm: '45px',
    pSm: '15px',
    fsSm: '14px',
    lhSm: '15px',
    fsLg: '14px',
    lhLg: '15px',
  },
  button: {
    minWSm: '220px',
    pSm: '14px 15px 13px',
    fsSm: '14px',
    lhSm: '16px',
    lsSm: '1px',
    fw: '600',
    submit: {
      minWSm: '220px',
      pSm: '0 15px',
    },
    pill: {
      minWSm: '126px',
      pSm: '10px 20px 9px',
      fsSm: '12px',
      lhSm: '12px',
      minWLg: '200px',
      pLg: '14px 40px 13px',
      fw: '600',
    },
  },
  shared: {
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.05), 0 3px 10px 0 rgba(0,0,0,0.1);',
  },
}

export default theme
