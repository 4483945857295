import React from 'react'

const TrainingSubLayout = ({ children, ...props }) => {
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default TrainingSubLayout
