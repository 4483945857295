import React from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  margin-left: calc(var(--site-x-padding) * -1);
  margin-right: calc(var(--site-x-padding) * -1);

  > div {
    width: max-content;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    text-align: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    > div {
      max-width: 300px;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        min-width: max-content;
        margin: 6px var(--site-x-padding);
        background-color: ${props => props.theme.colors.lighterGray};
        border: 1px solid ${props => props.theme.colors.lightBorder};
        border-radius: 16px;

        > a,
        > button {
          min-width: 80px;
          padding: 9px 10px;
          border-radius: 16px;
          white-space: nowrap;
          text-decoration: none;
          font-size: 11px;
          line-height: 11px;
          color: ${props => props.theme.colors.lightGray};
          font-weight: 600;
          cursor: pointer;

          @media screen and (max-width: ${props =>
    props.theme.responsive.small}) {
            padding: 9px 12px;
          }

          @media not all and (pointer: coarse) {
            &:hover {
              color: ${props => props.theme.colors.darkText};
            }
          }

          &[aria-current='page'],
          &.is-active {
            background-color: white;
            margin: -1px; // Account for border
            border: 1px solid ${props => props.theme.colors.lightBorder};
            color: ${props => props.theme.colors.darkText};
          }
        }
      }
    }

    @media screen and (min-width: ${(props) => props.theme.containerWidths.small}) {
      > div {
        max-width: 100%;
        min-width: max-content;

        > div {
          flex-wrap: nowrap;
        }
      }
    }

  }
`

const FilterNav = ({ children, ...props }, ref) => (
  <Outer {...props}>
    <div ref={ref}>
      <div>
        <div>{children}</div>
      </div>
    </div>
  </Outer>
)

export default React.forwardRef(FilterNav)
