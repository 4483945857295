import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { PageContext } from '../context/ApplicationState'
import Container from './Container'
import Layout from './Layout'
import PageHeader from './PageHeader'
import SEO from './SEO'
import StickyContainer from './StickyContainer'

const EventsLayout = ({ children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState((pageState) => ({
      ...pageState,
      hasStickySiteNav: false,
      hasHeroSection: true,
    }))
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "Events" } }) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
    }
  `)

  const heroImageMobile =
    data.allContentfulPageContext.edges[0].node.heroImageMobile
  const heroImageDesktop =
    data.allContentfulPageContext.edges[0].node.heroImageDesktop

  return (
    <Layout {...props}>
      <SEO />

      <PageHeader
        imageMobile={heroImageMobile}
        imageDesktop={heroImageDesktop}
        title={props.pageName}
      />
      <StickyContainer
        stickyNavLinks={
          <>
            <Link to="/events/" activeClassName="active-page-link">
              Calendar
            </Link>
            <Link
              to={`/events/artist-reunion/`}
              activeClassName="active-page-link"
            >
              Artist Reunion
            </Link>
            <Link
              to={`/events/virtual-meeting/`}
              activeClassName="active-page-link"
            >
              Virtual Meeting
            </Link>
            <Link
              to={`/events/incentive-trip/`}
              activeClassName="active-page-link"
            >
              Reward Trip
            </Link>
            <Link to={`/events/subscribe/`} activeClassName="active-page-link">
              Subscribe
            </Link>
          </>
        }
      >
        <Container className="wrap">{children}</Container>
      </StickyContainer>
    </Layout>
  )
}

export default EventsLayout
