import axios from 'axios'
import React from 'react'
import SiteNav from './src/components/SiteNav'
import ApplicationStateProvider from './src/context/ApplicationState'
import { isLoggedIn } from './src/services/auth'

export const wrapRootElement = ({ element }) => {
  return (
    <ApplicationStateProvider>
      {isLoggedIn() && <SiteNav />}
      {element}
    </ApplicationStateProvider>
  )
}
export const onRouteUpdate = () => {
  if (process.env.GATSBY_CONTEXT === 'production') {
    axios.get(`${process.env.GATSBY_NETLIFY_API_BASE_URL}sites/${process.env.GATSBY_NETLIFY_SITE_ID}/deploys`, {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_NETLIFY_API_TOKEN}`,
      },
      params: {
        per_page: 10,
      },
    }).then(function (response) {
      const lastBuildObj = response.data.filter(deploy => deploy.name === 'artisthq' && deploy.context === process.env.GATSBY_CONTEXT && deploy.branch === 'master' && deploy.state === 'ready')[0]

      const lastKnownBuildDate = localStorage.getItem('last-site-build') ? new Date(localStorage.getItem('last-site-build')) : null
      const lastBuildDate = lastBuildObj ? new Date(lastBuildObj.created_at) : null

      if (lastBuildDate && lastKnownBuildDate && lastBuildDate > lastKnownBuildDate) {
        window.location.reload(true)
      }
      if (lastBuildDate) {
        localStorage.setItem('last-site-build', lastBuildObj.created_at)
      }
    }).catch(function (error) {
      //console.log('Error: ' + error)
    })
  }
  try {
    navigator.serviceWorker.getRegistration().then((reg) => {
      reg && reg.update()
    })
  } catch (err) {
    // FALLBACK: probably an in app iOS browser
    function reqListener () {
      const etag = this.getResponseHeader('etag')
      const cachedEtag = window.localStorage.getItem('cached-etag')
      // if the sw file has changed cache the new etag and reload
      if (etag != cachedEtag) {
        window.localStorage.setItem('cached-etag', etag)
        window.location.reload()
      }
    }

    const req = new XMLHttpRequest()
    req.addEventListener('load', reqListener)
    req.open('GET', `${window.location.origin}/sw.js`)
    req.send()
  }
}

export const onServiceWorkerUpdateReady = () => {
  // manual reload for PWAs
  window.location.reload()
}
