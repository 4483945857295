import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { PageContext } from '../context/ApplicationState'
import Layout from './Layout'
import PageHeader from './PageHeader'
import ReunionHeader from './ReunionHeader'
import SEO from './SEO'
import StickyContainer from './StickyContainer'

const ArtistReunionLayout = ({ location, children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState({
      ...pageState,
      pageName: 'artist-reunion',
      hasStickySiteNav: false,
      hasHeroSection: true,
    })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "2024 Reunion" } }) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
    }
  `)

  const heroImageMobile =
    data.allContentfulPageContext.edges[0].node.heroImageMobile
  const heroImageDesktop =
    data.allContentfulPageContext.edges[0].node.heroImageDesktop

  return (
    <Layout {...props}>
      <SEO shareImagePath="/images/share-reunion.jpg" shareImageHeight={625} />
      <PageHeader
        page="artist-reunion"
        imageMobile={heroImageMobile}
        imageDesktop={heroImageDesktop}
      >
        <ReunionHeader />
      </PageHeader>
      <StickyContainer
        stickyNavLinks={
          <>
            <Link
              to="/events/artist-reunion/"
              activeClassName="active-page-link"
            >
              Overview
            </Link>
            <Link
              to={`/events/artist-reunion/schedule/`}
              activeClassName="active-page-link"
              partiallyActive
            >
              Agenda
            </Link>
            <Link
              to={`/events/artist-reunion/tickets/`}
              activeClassName="active-page-link"
            >
              Tickets
            </Link>
            {/*
            <Link
              to={`/events/artist-reunion/breakout-sessions/`}
              activeClassName="active-page-link"
            >
              Breakout Sessions
            </Link>
            <Link
              to={`/events/artist-reunion/classes/`}
              activeClassName="active-page-link"
            >
              Makeup Classes
            </Link>
            */}
            <Link
              to={`/events/artist-reunion/venue/`}
              activeClassName="active-page-link"
            >
              Hotel
            </Link>
            <Link
              to={`/events/artist-reunion/speakers/`}
              activeClassName="active-page-link"
            >
              Speakers
            </Link>
            <Link
              to={`/events/artist-reunion/parties/`}
              activeClassName="active-page-link"
            >
              Parties
            </Link>
            <Link
              to={`/events/artist-reunion/recognition/`}
              activeClassName="active-page-link"
            >
              Artist Recognition
            </Link>
            <Link
              to={`/events/artist-reunion/competition/`}
              activeClassName="active-page-link"
            >
              Glow-Up Competition
            </Link>
            <Link
              to={`/events/artist-reunion/events/`}
              activeClassName="active-page-link"
            >
              Love is Never Wasted Foundation
            </Link>
            <Link
              to={`/events/artist-reunion/policies-and-faq/`}
              activeClassName="active-page-link"
            >
              Policies & FAQ
            </Link>
          </>
        }
      >
        {children}
      </StickyContainer>
    </Layout>
  )
}

export default ArtistReunionLayout
