import { navigate } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import styled from 'styled-components'
import { PageContext } from '../context/ApplicationState'
import { getProductsByWarehouseId } from '../data/product/extraMetadata'
import Container from './Container'
import ErrorMessage from './ErrorMessage'
import FilterNav from './FilterNav'
import Layout from './Layout'
import LoadingSpinner from './LoadingSpinner'
import NoResultsText from './NoResultsText'
import PageHeader from './PageHeader'
import SEO from './SEO'
import StickyContainer from './StickyContainer'
import useAxiosGet from './useAxiosGet'

const UpdatedOnMessage = styled.p`
  margin-bottom: 18px;
  text-align: center;
  .note {
    font-size: 13px;
    line-height: 17px;
    color: ${props => props.theme.colors.grayTextAlt};
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    .note {
      font-size: 14px;
    }
  }
`

const ProductsList = styled.ul`
  display: grid;
  grid-gap: 20px;
  max-width: ${props => props.theme.containerWidths.medium};
  margin: 30px auto 0;
`

const ProductItem = styled.li`
  display: flex;
  align-items: center;
  margin: 7px 0;
  font-size: 14px;
  line-height: 18px;
`

const ProductImageWrapper = styled.span`
  width: 18.75vw;
  max-width: 70px;
  margin-right: 10px;
  flex-shrink: 0;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    width: 14vw;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    max-width: 100px;
  }
`

const ProductTitle = styled.h3`
  font-family: ${props => props.theme.typography.sansSerif};
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 20px;
    line-height: 24px;
  }
`

const ProductDetails = styled.div`
  .headline--small {
    margin: 0 0 10px;
  }
`

const InventoryLayout = ({ location, children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: false,
      hasHeroSection: true,
    }))
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "Inventory" } }) {
        edges {
          node {
            ...HeroImageSettings
            text
          }
        }
      }
    }
  `)

  const filterStrings = {
    outOfStock: 'out',
    lowStock: 'low',
    discontinued: 'discontinued',
  }
  const routePaths = {
    outOfStock: '/inventory/',
    lowStock: '/inventory/low-stock/',
    discontinued: '/inventory/discontinued/',
  }
  const [filterType, setFilterType] = useState(filterStrings.outOfStock)

  const warehouses = [
    {
      id: 1,
      name: 'United States',
    },
    {
      id: 2,
      name: 'Canada',
    },
  ]

  const [selectedWarehouseIndex, setSelectedWarehouseIndex] = useState(0)
  const LOCALSTORAGE_KEY = 'warehouseIndexForInventory'
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    LOCALSTORAGE_KEY,
    null
  )

  useEffect(() => {
    document.querySelector('body').classList.add('has-light-header')
    return () =>
      document.querySelector('body').classList.remove('has-light-header')
  }, [])

  useEffect(() => {
    const urlQueryIndexMatch = warehouses.findIndex(warehouse =>
      window.location.search.includes(
        `warehouse=${warehouse.name.replace(/\s/g, '')}`
      )
    )

    if (urlQueryIndexMatch !== -1) {
      navigate(
        `?warehouse=${warehouses[urlQueryIndexMatch].name.replace(/\s/g, '')}`,
        { replace: true }
      )
      setSelectedWarehouseIndex(urlQueryIndexMatch)
    } else if (localStorageValue === null) {
      navigate(
        `?warehouse=${warehouses[selectedWarehouseIndex].name.replace(
          /\s/g,
          ''
        )}`,
        { replace: true }
      )
    } else {
      for (const [i, _] of warehouses.entries()) {
        if (localStorageValue === i) {
          setSelectedWarehouseIndex(localStorageValue)
          navigate(
            `?warehouse=${warehouses[localStorageValue].name.replace(
              /\s/g,
              ''
            )}`,
            { replace: true }
          )
          break
        }
      }
    }

    if (window.location.pathname.includes(routePaths.lowStock)) {
      setFilterType(filterStrings.lowStock)
    }
    if (window.location.pathname.includes(routePaths.discontinued)) {
      setFilterType(filterStrings.discontinued)
    }
  }, [selectedWarehouseIndex, localStorageValue, filterType])

  const handleFilter = value => {
    if (
      value === filterStrings.outOfStock &&
      !window.location.pathname.includes(filterStrings.outOfStock)
    ) {
      navigate(routePaths.outOfStock, { replace: true })
    }
    if (
      value === filterStrings.lowStock &&
      !window.location.pathname.includes(filterStrings.lowStock)
    ) {
      navigate(routePaths.lowStock, { replace: true })
    }
    if (
      value === filterStrings.discontinued &&
      !window.location.pathname.includes(filterStrings.discontinued)
    ) {
      navigate(routePaths.discontinued, { replace: true })
    }
    setFilterType(value)
  }

  const handleWarehouse = index => {
    setLocalStorageValue(index)
    setSelectedWarehouseIndex(index)
    navigate(`?warehouse=${warehouses[index].name.replace(/\s/g, '')}`, {
      replace: true,
    })
  }

  const extraMetadata = getProductsByWarehouseId(
    warehouses[selectedWarehouseIndex].id
  )
  const { response, loading, error } = useAxiosGet({
    url: `https://www.seintofficial.com/api/warehouses/${warehouses[selectedWarehouseIndex].id}/items`,
    trigger: warehouses[selectedWarehouseIndex].id.toString(),
  })

  const { data: apiProducts } = response || {}

  const filteredProducts =
    apiProducts &&
    apiProducts.data.filter(product => {
      const matchingMetadataProduct = extraMetadata.find(
        p => p.id === product.id
      )

      let matching = null

      if (matchingMetadataProduct && matchingMetadataProduct.discontinued) {
        product.discontinued = matchingMetadataProduct.discontinued
      }

      if (matchingMetadataProduct && matchingMetadataProduct.restockETA) {
        product.restockETA = matchingMetadataProduct.restockETA
      }

      if (matchingMetadataProduct && matchingMetadataProduct.discontinuedMsg) {
        product.discontinuedMsg = matchingMetadataProduct.discontinuedMsg
      }

      if (filterType === filterStrings.outOfStock && !product.discontinued) {
        matching =
          matchingMetadataProduct &&
          matchingMetadataProduct.stockLevel.out >= product.stock
      }
      if (filterType === filterStrings.lowStock) {
        matching =
          matchingMetadataProduct &&
          matchingMetadataProduct.stockLevel.out < product.stock &&
          matchingMetadataProduct.stockLevel.low >= product.stock
      } else if (filterType === filterStrings.discontinued) {
        matching =
          matchingMetadataProduct &&
          matchingMetadataProduct.stockLevel.low >= product.stock &&
          matchingMetadataProduct.discontinued === true
      }
      return matching
    })

  let noResultsText = ''
  if (filterType === filterStrings.outOfStock) {
    noResultsText = 'out of stock'
  }
  if (filterType === filterStrings.lowStock) {
    noResultsText = 'low stock'
  }
  if (filterType === filterStrings.discontinued) {
    noResultsText = 'discontinued'
  }

  return (
    <Layout {...props}>
      <SEO />

      <PageHeader
        page="inventory"
        imageMobile={
          data.allContentfulPageContext.edges[0].node.heroImageMobile
        }
        imageDesktop={
          data.allContentfulPageContext.edges[0].node.heroImageDesktop
        }
        title={props.pageName}
      />

      <StickyContainer
        stickyNavLinks={
          <>
            <button
              onClick={() => handleFilter(filterStrings.outOfStock)}
              className={
                filterType === filterStrings.outOfStock
                  ? 'active-page-link'
                  : undefined
              }
            >
              Out of Stock
            </button>
            <button
              onClick={() => handleFilter(filterStrings.lowStock)}
              className={
                filterType === filterStrings.lowStock
                  ? 'active-page-link'
                  : undefined
              }
            >
              Low Stock
            </button>
          </>
        }
      >
        <Container className="padded-section wrap">
          {data.allContentfulPageContext.edges[0].node.text && (
            <p className="headline--intro center-align">
              {data.allContentfulPageContext.edges[0].node.text}
            </p>
          )}
          <UpdatedOnMessage>
            <span className="note">
              For more immediate updates, subscribe to the Artist Telegram Channel.
            </span>
          </UpdatedOnMessage>
          {error ? (
            <ErrorMessage className="section" />
          ) : !apiProducts || loading ? (
            <div className="section">
              <LoadingSpinner />
            </div>
          ) : (
            <React.Fragment>
              <FilterNav>
                {warehouses.map((warehouse, i) => (
                  <button
                    key={warehouse.id}
                    className={
                      selectedWarehouseIndex === i ? 'is-active' : null
                    }
                    onClick={() => handleWarehouse(i)}
                  >
                    {warehouse.name}
                  </button>
                ))}
              </FilterNav>
              {filteredProducts.length > 0 ? (
                <ProductsList>
                  {filteredProducts.map(item => (
                    <ProductItem key={item.id}>
                      <ProductImageWrapper>
                        <img src={item.image} alt={item.title} />
                      </ProductImageWrapper>
                      <ProductDetails>
                        <ProductTitle>{item.title}</ProductTitle>
                        <p className="detail">
                          {item.discontinuedMsg
                            ? item.discontinuedMsg
                            : filterType === filterStrings.outOfStock ||
                              filterType === filterStrings.lowStock
                            ? item.discontinued
                              ? 'Discontinued – will not restock'
                              : item.restockETA
                              ? `Expected restock date: ${item.restockETA}`
                              : ''
                            : filterType === filterStrings.discontinued
                            ? item.discontinuedMsg
                              ? item.discontinuedMsg
                              : ''
                            : ''}
                        </p>
                      </ProductDetails>
                    </ProductItem>
                  ))}
                </ProductsList>
              ) : (
                <NoResultsText>
                  There are currently no items that are {noResultsText}.
                </NoResultsText>
              )}
            </React.Fragment>
          )}
        </Container>
      </StickyContainer>
    </Layout>
  )
}

export default InventoryLayout
