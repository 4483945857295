import axios from 'axios'
import jwt_decode from 'jwt-decode'

const tokenKey = 'userToken'

const setToken = (token) => window.localStorage.setItem(tokenKey, token)
const unsetToken = () => window.localStorage.removeItem(tokenKey)

export const handleLogin = async ({ username, password }) => {
  try {
    const authResult = await axios.post('/.netlify/functions/auth-api', {
      username: username,
      password: password,
    })
    setToken(authResult.data)
    return true
  } catch (error) {
    console.log(error)
  }
  return false
}

export const isLoggedIn = () => {
  if (typeof window === 'undefined') {
    return false
  }

  const localToken = window?.localStorage?.getItem(tokenKey)
  const token = localToken ? jwt_decode(localToken) : null

  if (token?.customerType === 1) {
    return true
  }

  unsetToken()
  return false
}

export const logout = (callback) => {
  unsetToken()
  callback()
}
