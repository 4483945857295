import { Link } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { PageContext } from '../context/ApplicationState'
import Layout from './Layout'
import PageHeader from './PageHeader'
import SEO from './SEO'
import StickyContainer from './StickyContainer'
import VirtualMeetingHeader from './VirtualMeetingHeader'

const VirtualMeetingLayout = ({ location, children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState({
      ...pageState,
      pageName: 'virtual-meeting',
      hasStickySiteNav: false,
      hasHeroSection: true,
    })
  }, [])

  /*
  -- Hero Image Code Below --
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(
        filter: { title: { eq: "Leadership Summit" } }
      ) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
    }
  `)

  const heroImageMobile =
    data.allContentfulPageContext.edges[0].node.heroImageMobile
  const heroImageDesktop =
    data.allContentfulPageContext.edges[0].node.heroImageDesktop
  */

  return (
    <Layout {...props}>
      <SEO
        shareImagePath="/images/share-leadership.jpg"
        shareImageHeight={1326}
      />
      <PageHeader
        page="virtual-meeting"
      >
        <VirtualMeetingHeader />
      </PageHeader>
      {/*
      <PageHeader
        page="virtual-meeting"
        imageMobile={heroImageMobile}
        imageDesktop={heroImageDesktop}
      >
        <VirtualMeetingHeader />
      </PageHeader>
      */}
      <StickyContainer
        stickyNavLinks={
          <>
            <Link
              to="/events/virtual-meeting/"
              activeClassName="active-page-link"
            >
              Overview
            </Link>
            <Link
              to="/events/virtual-meeting/schedule/"
              activeClassName="active-page-link"
            >
              Agenda
            </Link>
            <Link
              to="/events/virtual-meeting/speakers/"
              activeClassName="active-page-link"
            >
              Speakers
            </Link>
            <Link
              to="/events/virtual-meeting/breakout-sessions/"
              activeClassName="active-page-link"
            >
              Breakout Sessions
            </Link>
            <Link
              to="/events/virtual-meeting/promotion/"
              activeClassName="active-page-link"
            >
              Artist Promotion
            </Link>
            <Link
              to="/events/virtual-meeting/tickets/"
              activeClassName="active-page-link"
            >
              Tickets
            </Link>
            <Link
              to="/events/virtual-meeting/parties/"
              activeClassName="active-page-link"
            >
              Party
            </Link>
            <Link
              to="/events/virtual-meeting/recognition/"
              activeClassName="active-page-link"
            >
              Seint Awards
            </Link>
            {/*
            <Link
              to="/events/virtual-meeting/policies-and-faq/"
              activeClassName="active-page-link"
            >
              FAQ
            </Link>
            */}
          </>
        }
      >
        {children}
      </StickyContainer>
    </Layout>
  )
}

export default VirtualMeetingLayout
