module.exports = {
  siteTitle: 'Seint Gallery',
  siteTitleAlt: 'Seint Gallery | Artist Headquarters', // This allows an alternative site title for SEO schema.
  publisher: 'Seint', // Organization name used for SEO schema
  siteDescription: 'Resources, news, events, and updates for Seint artists.',
  siteUrl: 'https://www.seintgallery.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Seint', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.instagram.com/seintartists/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@seintevents', // Change for Twitter Cards
  shortTitle: 'Seint Gallery', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/images/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 1200, // Change to the height of your default share image
  siteIcon: './src/images/favicon.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#FFFFFF', // Used for Offline Manifest
  themeColor: '#FFFFFF', // Used for Offline Manifest
  copyright: `Copyright © ${new Date().getFullYear()}`, // Copyright string for the RSS feed
}
