import { createGlobalStyle } from 'styled-components'
// @ts-ignore
import butlerLight from '../../static/fonts/butler-light.woff2'
import paperTexture from '../../static/images/paper-texture.jpg'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
  @font-face{
    font-display: fallback;
    font-family: 'Butler Light';
    src: ${`url(${butlerLight}) format('woff2')`};
    font-weight: 300;
    font-style: normal;
  }
  @font-face{
    font-display: fallback;
    font-family: 'Butler Light remote';
    src: url("https://s3-us-west-1.amazonaws.com/seintgallery.com/butler-light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
  }

  :root {
    --site-x-padding: ${theme.siteXPaddingSm};
    --section-padding: ${theme.section.topSm};

    @media screen and (min-width: ${theme.responsive.medium}) {
      --section-padding: ${theme.section.topMd};
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      --site-x-padding: ${theme.siteXPaddingLg};
      --section-padding: ${theme.section.topLg};
    }
  }

  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* Push site footer to bottom when page content doesn't fill the viewport */
  /* html, body {
    height: 100%;
  } */

  body {
    width: 100%;
    line-height: 1;
    font-size: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 300;
    font-family: ${theme.typography.sansSerif};
    color: ${theme.colors.darkText};
    -webkit-tap-highlight-color: ${theme.colors.webkitTapHighlight};

    @media (-o-min-device-pixel-ratio: 13/10), (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 120dpi) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }

    p {
      font-weight: 300;
    }
  }

  body,
  .site-root {
    background-color: white;
  }

  .has-paper-bg {
    &, .sticky-nav.is-stuck {
      background: #f5f4ef url(${paperTexture}) repeat;
    }

    header, footer, .site-root, .sticky-nav {
      background: transparent;
    }
  }

  .site-root {
    position: relative;
    min-height: ${`calc(100vh - ${theme.navMenu.heightSm})`};
    // JS will override this style and set the min-height to the window.innerHeight with inlined CSS on load and scroll
    // events because iOS Safari has 100vh issues because of the browser chrome not being calculated
    display: flex;
    flex-direction: column;
    transition: min-height 0ms;

    > * {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      z-index: 1;
    }

    > header,
    > footer {
      width: 100%;
      flex-grow: 0;
    }

    &.page--article,
    &.page--recognition,
    &.page--top-artist-profile,
    &.page--other {
      /* opacity: 0;
      animation: fadeIn 250ms ease 250ms forwards;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      } */

      > *:first-child {
        margin-top: ${theme.navMenu.heightSm};
      }

      @media screen and (min-width: ${theme.responsive.large}) {
        > *:first-child {
          margin-top: ${theme.navMenu.heightLg}
        }
      }
    }

    /* #siteBanner + & {
      min-height: calc(100vh - ${theme.siteBanner.heightSm});
      transition: min-height ${theme.siteBanner.timing} ease ${theme.siteBanner.delay
  };
    } */
    @media screen and (min-width: ${theme.responsive.large}) {
      min-height: ${`calc(100vh - ${theme.navMenu.heightLg})`};

      > header {
        + * {
          min-height: 450px;
        }
      }
    }
  }
  /* End styles for sticking site footer to the bottom */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  /* Fix for jumpy page transitions…? See https://gist.github.com/javierlopezdeancos/1331b868f20f064afe7c */
  /* @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  } */
  /* End styles for jumpy page fix */

  a {
    color: ${theme.colors.primary};
    text-decoration: underline;

    .page--artist-reunion > div & {
      color: ${theme.colors.accentReunion};
    }

    .page--incentive-trip & {
      color: ${theme.colors.accentIncentiveTrip};
      text-decoration: none;
    }

    .page--virtual-meeting & {
      color: ${theme.colors.accentVirtualMeeting};
      text-decoration: none;
    }
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  h1, h2, h3, h4, h5 {
    margin-top: ${theme.typography.h.mtSm};
    margin-bottom: ${theme.typography.h.mbSm};
  }

  h1, h2 {
    font-size: ${theme.typography.h2.fsSm};
    line-height: ${theme.typography.h2.lhSm};
  }

  h3 {
    font-size: ${theme.typography.h3.fsSm};
    line-height: ${theme.typography.h3.lhSm};
  }

  h4 {
    font-size: ${theme.typography.h4.fsSm};
    line-height: ${theme.typography.h4.lhSm};
  }

  h5 {
    font-size: ${theme.typography.h5.fsSm};
    line-height: ${theme.typography.h5.lhSm};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.typography.serif};
    font-weight: ${theme.typography.displayFw};

    &:first-child {
      margin-top: 0;
    }
  }

  h1, h2, h3 {
    .page--incentive-trip & {
      font-family: ${theme.typography.serif};
      font-weight: 300;
    }

    .page--virtual-meeting & {
      font-family: ${theme.typography.serif};
      font-weight: 300;
      color: ${theme.colors.accentVirtualMeeting};
    }
  }

  p {
    font-size: ${theme.typography.body.fsSm};
    line-height: ${theme.typography.body.lhSm};
    color: inherit;

    @media screen and (min-width: ${theme.responsive.large}) {
      font-size: ${theme.typography.body.fsLg};
      line-height: ${theme.typography.body.lhLg};
    }

    + p {
      margin-top: ${theme.typography.body.lhSm};

      @media screen and (min-width: ${theme.responsive.large}) {
        margin-top: ${theme.typography.body.lhLg};
      }
    }
    + .note {
      margin-top: calc(${theme.typography.body.lhSm} * 0.5);

      @media screen and (min-width: ${theme.responsive.large}) {
        margin-top: calc(${theme.typography.body.lhLg} * 0.5);
      }
    }
  }

  sup {
    display: inline-block;
    position: relative;
    top: -5px;
    font-size: 75%;
    margin: 0 1px;
  }

  .site-nav {
    a {
      -webkit-tap-highlight-color: ${theme.colors.webkitTapHighlight};
    }
  }

  img, video {
    display: block;
  	width: 100%;
  	height: auto;
  }

  [role="img"][aria-label] {
    font-family: ${theme.typography.emoji} !important;
  }

  hr {
    height: 1px;
    background-color: ${theme.colors.lightBorder};
    border: none;
    margin: 15px 0;

    @media screen and (min-width: ${theme.responsive.large}) {
      margin: 20px 0;
    }
  }

  form {
    fieldset,
    legend {
      margin-top: ${theme.typography.sectionTitle.secondary.mtSm};
    }

    legend {
      /* margin-bottom: ${theme.typography.sectionTitle.secondary.mbSm}; */
      margin-bottom: 6px;
      font-size: ${theme.typography.sectionTitle.secondary.fsSm};
      line-height: ${theme.typography.sectionTitle.secondary.lhSm};
      font-weight: ${theme.typography.sectionTitle.secondary.fw};

      /* &:first-child {
        margin-top: 0;
      } */
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      fieldset,
      legend {
        margin-top: ${theme.typography.sectionTitle.secondary.mtLg};
      }

      legend {
        margin-bottom: ${theme.typography.sectionTitle.secondary.mbLg};
        font-size: ${theme.typography.sectionTitle.secondary.fsLg};
        line-height: ${theme.typography.sectionTitle.secondary.lhLg};
        font-weight: ${theme.typography.sectionTitle.secondary.fw};
      }
    }
  }

  button,
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;

    &:focus {
      outline: none;
    }

    &:invalid {
      box-shadow: none;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  a[class*="btn--" i] {
    text-decoration: none;
  }

  .btn {
    display: inline-block;
    min-width: ${theme.button.minWSm};
    margin-left: auto;
    margin-right: auto;
    padding: ${theme.button.pSm};
    font-size: ${theme.button.fsSm};
    font-weight: ${theme.button.fw};
    line-height: ${theme.button.lhSm};
    letter-spacing: ${theme.button.lsSm};
    text-transform: uppercase;
    text-align: center;
    border: 1px solid ${theme.colors.buttonBorder};

    @media screen and (min-width: ${theme.responsive.large}) {
      border-width: 1.5px;
    }
  }

  .btn--submit {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: ${theme.button.submit.minWSm};
    height: ${theme.input.minHSm};
    padding: ${theme.button.submit.pSm};
    background-color: ${theme.colors.darkBg};
    font-size: ${theme.button.fsSm};
    font-weight: ${theme.button.fw};
    line-height: ${theme.button.lhSm};
    letter-spacing: ${theme.button.lsSm};
    text-transform: uppercase;
    text-align: center;
    color: white !important; // for <a> elements
    text-decoration: none; // for <a> elements

    &[disabled] {
      background-color: ${theme.colors.disabledButtonBg};
      color: ${theme.colors.darkTex};
    }

    .page--artist-reunion & {
      background-color: ${theme.colors.accentReunion};
    }
  }

  .body-content {
    .btn--submit {
      font-weight: ${theme.button.fw};
    }
  }

  .btn--pill {
    display: inline-block;
    min-width: ${theme.button.pill.minWSm};
    padding: ${theme.button.pill.pSm};
    background-color: transparent;
    font-size: ${theme.button.pill.fsSm};
    font-weight: ${theme.button.pill.fw};
    line-height: ${theme.button.pill.lhSm};
    letter-spacing: ${theme.button.lsSm};
    text-transform: uppercase;
    text-align: center;
    color: ${theme.colors.darkText} !important; // for <a> elements
    text-decoration: none; // for <a> elements
    border: 1px solid ${theme.colors.mediumBorder};
    border-radius: 100px;

    @media screen and (min-width: ${theme.responsive.large}) {
      min-width: ${theme.button.pill.minWLg};
      padding: ${theme.button.pill.pLg};
    }
  }

  .btn--transparent {
    padding: 15px 5px;
  }

  .btn--with-icon {
    > span,
    > i,
    > svg {
      display: inline-block;
      position: relative;
      top: -1.5px;
      margin-left: 4px;
    }
  }

  button[type="submit"] {
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  a.link-inherit {
    font: inherit;
    color: inherit !important;
  }

  .link-underline {
    text-decoration: underline;
  }

  label {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: ${theme.colors.darkText};
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="submit"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  select,
  textarea {
    min-height: ${theme.input.minHSm};
    padding: 0 ${theme.input.pSm};
    font-size: ${theme.input.fsSm};
    border: none;
    border-radius: 0;
    appearance: none;
    font-family: ${theme.typography.sansSerif};
    color: ${theme.colors.darkText};
    background-color: white;
    border: 1px solid ${theme.colors.lightBorder};

    &::placeholder {
      color: ${theme.colors.placeholderText};
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="submit"],
  input[type="search"],
  input[type="tel"],
  input[type="url"] {
    padding-top: ${`calc((${theme.input.minHSm} - 7px - ${theme.input.fsSm}) / 2);`};
    padding-bottom: ${`calc((${theme.input.minHSm} - 7px - ${theme.input.fsSm}) / 2);`};
  }

  /* footer {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="submit"],
    input[type="search"],
    input[type="tel"],
    input[type="url"] {
      background-color: ${theme.colors.nearlyWhite};
      border: 1px solid ${theme.colors.lightBorder};
    }
  } */

  textarea {
    min-height: 260px;
    padding: ${theme.input.pSm};
    resize: none;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      display: block;
      padding-left: 26px; // pseudo el width plus gap
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;

      &::before {
        content: '';
        position: absolute;
        top: -1.5px;
        left: 0;
        display: inline-block;
        width: 26px; // Testing
        height: 26px; // Testing
        vertical-align: text-top;
        transition: background 120ms ease-in, border 60ms ease-in 60ms;
        color: ${theme.colors.unchecked};
        border: 1px solid ${theme.colors.unchecked};
        border-radius: 50%;
        background: transparent url('data:image/svg+xml;utf8,<svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="${theme.colors.unchecked.replace(
    '#',
    '%23'
  )}" fill-rule="nonzero" points="12.367731 0.464463497 13.4178751 1.5355365 5.25555335 9.53836539 0.472496548 4.80589218 1.52750345 3.73960871 5.26034344 7.43297176"></polygon></svg>') center center no-repeat;
      }
    }

    &:checked + label {
      &::before {
        background: ${theme.colors.primary
  } url('data:image/svg+xml;utf8,<svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="${'white'}" fill-rule="nonzero" points="12.367731 0.464463497 13.4178751 1.5355365 5.25555335 9.53836539 0.472496548 4.80589218 1.52750345 3.73960871 5.26034344 7.43297176"></polygon></svg>') center center no-repeat;
        border: none;
      }
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      & + label {
        &::before {
          top: -3px;
        }
      }
    }
  }

  .select-wrapper {
    position: relative;

    &::after {
      content: '';
      pointer-events: none;
      display: inline-block;
      position: absolute;
      top: calc((${theme.input.minHSm} / 2) - (8px / 2));
      right: 15px;
      width: 12.5px;
      height: 8px;
      transform: rotate(180deg);
      background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon id="Path" fill="${theme.colors.darkText.replace(
    '#',
    '%23'
  )}" fill-rule="nonzero" points="1.01615575 7.56237386 0 6.46153846 7 0 14 6.46153846 12.9838442 7.56237386 7 2.03882533"></polygon></svg>') center center / contain no-repeat;
    }
  }

  select {
    /* color: ${theme.colors.placeholderText}; */

    option {
      color: ${theme.colors.darkText};
    }

    option:first-child {
      color: ${theme.colors.placeholderText};
    }
  }

  blockquote {
    position: relative;
    max-width: 750px;
    margin: 30px auto;

    &::before {
      content: '';
      width: 52px;
      height: 41px;
      display: inline-block;
      margin-bottom: 5px;
      background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 52 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M42.9452003,-1.42108547e-14 L43.8616497,0.827874304 C39.5503943,5.14373406 39.1407196,7.00356522 38.7462753,11.0338845 C38.351831,15.0642037 40.8175092,19.0870518 43.5346132,21.7324709 C46.2517171,24.3778899 50.7532337,26.1946876 50.7532337,26.1946876 C50.8488383,26.7914781 51.7647899,31.0563615 49.5571402,35.0554577 C47.3494905,39.0545539 43.826509,41 39.1459085,41 C34.465308,41 25.4711579,36.5805025 27.7026645,23.671752 C29.1903355,15.0659184 34.2711808,7.17533442 42.9452003,-1.42108547e-14 Z M15.592322,-1.42108547e-14 L16.5087714,0.827874304 C12.197516,5.14373406 11.7878413,7.00356522 11.393397,11.0338845 C10.9989526,15.0642037 13.4646308,19.0870518 16.1817348,21.7324709 C18.8988388,24.3778899 23.4003553,26.1946876 23.4003553,26.1946876 C23.4959599,26.7914781 24.4119115,31.0563615 22.2042618,35.0554577 C19.9966121,39.0545539 16.4736306,41 11.7930301,41 C7.11242967,41 -1.88172041,36.5805025 0.349786156,23.671752 C1.8374572,15.0659184 6.91830247,7.17533442 15.592322,-1.42108547e-14 Z" fill="${theme.colors.darkBg.replace(
    '#',
    '%23'
  )}"></path></g></svg>') center center / contain no-repeat;
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      font-size: ${theme.typography.blockquote.fsLg};
      line-height: ${theme.typography.blockquote.lhLg};
    }
  }

  blockquote {
    font-family: ${theme.typography.serif};
    font-size: ${theme.typography.blockquote.fsSm};
    line-height: ${theme.typography.blockquote.lhSm};
    font-weight: ${theme.typography.displayFw};

    > p {
      font-family: inherit;
      font-style: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }

  .white-quote {
    blockquote {
      &::before {
        background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 52 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M42.9452003,-1.42108547e-14 L43.8616497,0.827874304 C39.5503943,5.14373406 39.1407196,7.00356522 38.7462753,11.0338845 C38.351831,15.0642037 40.8175092,19.0870518 43.5346132,21.7324709 C46.2517171,24.3778899 50.7532337,26.1946876 50.7532337,26.1946876 C50.8488383,26.7914781 51.7647899,31.0563615 49.5571402,35.0554577 C47.3494905,39.0545539 43.826509,41 39.1459085,41 C34.465308,41 25.4711579,36.5805025 27.7026645,23.671752 C29.1903355,15.0659184 34.2711808,7.17533442 42.9452003,-1.42108547e-14 Z M15.592322,-1.42108547e-14 L16.5087714,0.827874304 C12.197516,5.14373406 11.7878413,7.00356522 11.393397,11.0338845 C10.9989526,15.0642037 13.4646308,19.0870518 16.1817348,21.7324709 C18.8988388,24.3778899 23.4003553,26.1946876 23.4003553,26.1946876 C23.4959599,26.7914781 24.4119115,31.0563615 22.2042618,35.0554577 C19.9966121,39.0545539 16.4736306,41 11.7930301,41 C7.11242967,41 -1.88172041,36.5805025 0.349786156,23.671752 C1.8374572,15.0659184 6.91830247,7.17533442 15.592322,-1.42108547e-14 Z" fill="%23FFFFFF"></path></g></svg>') center center / contain no-repeat;
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .body-content {
    font-size: ${theme.typography.body.fsSm};
    line-height: ${theme.typography.body.lhSm};
    line-height: 1.4;

    a {
      text-decoration: underline;
    }

    strong {
      font-weight: 600;
    }

    em {
      font-style: italic;
    }

    ul:not([class]),
    ol:not([class]) {
      margin: ${theme.typography.body.lhSm} 0 12px;

      li {
        position: relative;
        display: block;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          vertical-align: text-top;
        }

        + li {
          margin-top: 10px;
        }
      }

      + p {
        margin-top: ${theme.typography.body.lhSm};
      }
    }

    ul:not([class]) {
      li {
        padding-left: 16px;

        &::before {
          top: 10px;
          width: 5px;
          height: 5px;
          background-color: ${theme.colors.darkBg};
          border-radius: 50%;
        }
      }
    }

    ol:not([class]) {
      counter-reset: simple;

      li {
        counter-increment: simple;
        padding-left: 20px;

        &::before {
          content: counter(simple) ".";
        }

        &:nth-child(n + 10) {
          padding-left: 24px;
        }
      }
    }

    &.has-drop-cap {
      > div:first-of-type {
        > p:first-of-type {
          &::first-letter {
            float: left;
            margin-top: 8px;
            padding-right: 12px;
            font-size: 75px;
            line-height: 64px;
            color: ${theme.colors.darkText};
            font-family: ${theme.typography.serif};
          }
        }
      }
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      font-size: ${theme.typography.body.fsLg};
      line-height: ${theme.typography.body.lhLg};
    }
  }

  .visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }

  .responsive-video-embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: 10px;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 160ms ease;
    pointer-events: none;

    .modal-active &,
    .menu-active & {
      background-color: rgba(0, 0, 0, 0.3);
      pointer-events: initial;
      transition: background-color 190ms ease;
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      display: none;
    }
  }

  .container {
    position: relative;
    width: 100%;
    max-width: ${theme.siteMaxWidth};
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section,
  .padded-section {
    width: 100%;
    flex-grow: 1;

    + .section,
    + .padded-section {
      margin-top: 0;
    }

    > .section:last-child,
    > .padded-section:last-child {
      margin-bottom: 0;
    }
  }

  /* footer.section {
    margin-top: calc(var(--section-padding) / 2);
  } */

  .section {
    margin: var(--section-padding) auto var(--section-padding);
  }

  .padded-section {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
  }

  .headline--large {
    width: 100%;
    max-width: ${theme.typography.headlines.large.width};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${theme.typography.headlines.large.mtSm};
    margin-bottom: ${theme.typography.headlines.large.mbSm};
    font-family: ${theme.typography.serif};
    font-size: ${theme.typography.headlines.large.fsSm};
    line-height: ${theme.typography.headlines.large.lhSm};

    .page--incentive-trip & {
      font-family: ${theme.typography.serif};
      font-weight: 300;
    }

    &:first-child {
      margin-top: 0;
    }

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlines.large.mtLg};
      margin-bottom: ${theme.typography.headlines.large.mbLg};
      font-size: ${theme.typography.headlines.large.fsLg};
      line-height: ${theme.typography.headlines.large.lhLg};
    }
  }

  .headline--medium {
    margin-top: ${theme.typography.headlines.medium.mtSm};
    margin-bottom: ${theme.typography.headlines.medium.mbSm};
    font-family: ${theme.typography.serif};
    font-size: ${theme.typography.headlines.medium.fsSm};
    line-height: ${theme.typography.headlines.medium.lhSm};

    .page--incentive-trip & {
      font-family: ${theme.typography.serif};
      font-weight: 300;
    }

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlines.medium.mtLg};
      margin-bottom: ${theme.typography.headlines.medium.mbLg};
      font-size: ${theme.typography.headlines.medium.fsLg};
      line-height: ${theme.typography.headlines.medium.lhLg};
    }
  }

  .headline--small {
    margin-top: ${theme.typography.headlines.small.mtSm};
    margin-bottom: ${theme.typography.headlines.small.mbSm};
    font-family: ${theme.typography.serif};
    font-size: ${theme.typography.headlines.small.fsSm};
    line-height: ${theme.typography.headlines.small.lhSm};

    .page--incentive-trip & {
      font-family: ${theme.typography.serif};
      font-weight: 300;
    }

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlines.small.mtLg};
      margin-bottom: ${theme.typography.headlines.small.mbLg};
      font-size: ${theme.typography.headlines.small.fsLg};
      line-height: ${theme.typography.headlines.small.lhLg};
    }
  }

  .headline--xs {
    margin-top: ${theme.typography.headlines.xs.mtSm};
    margin-bottom: ${theme.typography.headlines.xs.mbSm};
    font-family: ${theme.typography.serif};
    font-size: ${theme.typography.headlines.xs.fsSm};
    line-height: ${theme.typography.headlines.xs.lhSm};

    .page--incentive-trip & {
      font-family: ${theme.typography.serif};
      font-weight: 300;
    }

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlines.xs.mtLg};
      margin-bottom: ${theme.typography.headlines.xs.mbLg};
      font-size: ${theme.typography.headlines.xs.fsLg};
      line-height: ${theme.typography.headlines.xs.lhLg};
    }
  }

  .headline--hero {
    font-family: ${theme.typography.serifDisplay};
    font-size: ${theme.typography.headlines.hero.fsSm};
    font-style: ${theme.typography.headlines.hero.fStyle};
    line-height: ${theme.typography.headlines.hero.lhSm};
    text-transform: uppercase;

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlines.hero.mtLg};
      margin-bottom: ${theme.typography.headlines.hero.mbLg};
      font-size: ${theme.typography.headlines.hero.fsLg};
      line-height: ${theme.typography.headlines.hero.lhLg};
    }
  }

  .headline--with-line-design {
    --fg-color: ${theme.colors.darkText};
    --bg-color: white; // Default body bg color

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${theme.typography.headlines.withLines.mtSm};
    margin-bottom: ${theme.typography.headlines.withLines.mbSm};
    text-align: center;
    font-family: ${theme.typography.sansSerif};
    font-size: ${theme.typography.sectionTitle.fsSm};
    line-height: ${theme.typography.sectionTitle.lhSm};
    font-weight: ${theme.typography.sectionTitle.fw};
    text-transform: uppercase;
    letter-spacing: ${theme.typography.sectionTitle.lsSm};
    color: var(--fg-color);

    .page--artist-reunion & {
      --fg-color: ${theme.colors.accentReunion};
      font-family: ${theme.typography.serifDisplay};
      font-size: ${theme.typography.headlines.reunion.fsSm};
      font-style: ${theme.typography.headlines.reunion.fStyle};
      font-weight: 400;
      line-height: ${theme.typography.headlines.reunion.lhSm};
      letter-spacing: 0;
    }

    .page--incentive-trip & {
      --fg-color: ${theme.colors.accentIncentiveTrip};
      font-family: ${theme.typography.serif};
      font-weight: 300;
    }

    .page--virtual-meeting & {
      --fg-color: ${theme.colors.accentVirtualMeeting};
      font-family: ${theme.typography.serif};
    }

    > span {
      position: relative;
      z-index: 1;
      margin-left: ${theme.typography.headlines.withLines.minLineLength
  }; // minimum background line that must show
      margin-right: ${theme.typography.headlines.withLines.minLineLength
  }; // minimum background line that must show
      padding-left: 10px; // Gap size between letters and lines
      padding-right: 10px; // Gap size between letters and lines

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 0;
        z-index: -1;
        margin-left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        // show at most 80px left and 80px right (minus the minimum already added with padding above)
        width: calc(${theme.typography.headlines.withLines.minLineLength
  } + 100% + ${theme.typography.headlines.withLines.minLineLength});
        height: 1px;
        margin-top: -0.5px;
        background-color: var(--fg-color);
      }

      &::after {
        // "hide" the background line 10px left and 10px right
        width: 100%;
        height: 2px;
        margin-top: -1px;
        background-color: var(--bg-color);
      }

      @media screen and (min-width: ${theme.responsive.medium}) {
        margin-left: ${theme.typography.headlines.withLines.minLineLengthLg
  }; // minimum background line that must show
        margin-right: ${theme.typography.headlines.withLines.minLineLengthLg
  }; // minimum background line that must show

        &::before {
          // show at most 100px left and 80px right (minus the minimum already added with padding above)
          width: calc(${theme.typography.headlines.withLines.minLineLengthLg
  } + 100% + ${theme.typography.headlines.withLines.minLineLengthLg});
        }
      }
    }

    @media screen and (-webkit-min-device-pixel-ratio: 3),
      screen and (min-resolution: 288dpi) {
      > span {
        &::before {
          height: 0.5px;
        }

        &::after {
          height: 1px;
          margin-top: -0.5px;
        }
      }
    }

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-top: ${theme.typography.headlines.withLines.mtLg};
      margin-bottom: ${theme.typography.headlines.withLines.mbLg};
      font-size: ${theme.typography.sectionTitle.fsLg};
      line-height: ${theme.typography.sectionTitle.lhLg};
      letter-spacing: ${theme.typography.sectionTitle.lsLg};

      .page--artist-reunion & {
        font-size: ${theme.typography.headlines.reunion.fsLg};
        line-height: ${theme.typography.headlines.reunion.lhLg};
      }
    }
  }

  .headline--intro {
    width: 100%;
    max-width: ${theme.typography.headlines.intro.width};
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${theme.typography.headlines.intro.mbSm};
    font-size: ${theme.typography.headlines.intro.fsSm};
    line-height: ${theme.typography.headlines.intro.lhSm};
    font-weight: ${theme.typography.headlines.intro.fwSm};
    color: ${theme.colors.grayTextAlt};

    @media screen and (min-width: ${theme.responsive.medium}) {
      margin-bottom: ${theme.typography.headlines.intro.mbLg};
      font-size: ${theme.typography.headlines.intro.fsLg};
      line-height: ${theme.typography.headlines.intro.lhLg};
      font-weight: ${theme.typography.headlines.intro.fwLg};
    }
  }

  .page--artist-reunion,
  .page--virtual-meeting,
  .page--incentive-trip {
    .headline--reunion,
    .headline {
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 24px;
      text-transform: uppercase;

      @media screen and (min-width: ${theme.responsive.medium}) {
        font-size: 30px;
        line-height: 34px;
      }
    }

    .title {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      + p,
      + ul {
        margin-top: 8px;
      }
    }
  }

  .section-title {
    margin-bottom: ${theme.typography.sectionTitle.mbSm};
    font-family: ${theme.typography.sansSerif};
    font-size: ${theme.typography.sectionTitle.fsSm};
    line-height: ${theme.typography.sectionTitle.lhSm};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: ${theme.typography.sectionTitle.lsSm};
    font-weight: ${theme.typography.sectionTitle.fw};

    @media screen and (min-width: ${theme.responsive.large}) {
      margin-bottom: ${theme.typography.sectionTitle.mbLg};
      font-size: ${theme.typography.sectionTitle.fsLg};
      line-height: ${theme.typography.sectionTitle.lhLg};
      letter-spacing: ${theme.typography.sectionTitle.lsLg};
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .section-title--secondary {
    margin-bottom: ${theme.typography.sectionTitle.secondary.mbSm};
    font-family: ${theme.typography.sansSerif};
    font-size: ${theme.typography.sectionTitle.secondary.fsSm};
    line-height: ${theme.typography.sectionTitle.secondary.lhSm};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: ${theme.typography.sectionTitle.secondary.lsSm};
    font-weight: ${theme.typography.sectionTitle.secondary.fw};

    @media screen and (min-width: ${theme.responsive.large}) {
      margin-bottom: ${theme.typography.sectionTitle.secondary.mbLg};
      font-size: ${theme.typography.sectionTitle.secondary.fsLg};
      line-height: ${theme.typography.sectionTitle.secondary.lhLg};
      letter-spacing: ${theme.typography.sectionTitle.secondary.lsLg};
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .section-title--serif {
    font-family: ${theme.typography.serifDisplay};
    /* margin-bottom: ${theme.typography.sectionTitle.serif.mbSm}; */
    font-size: ${theme.typography.sectionTitle.serif.fsSm};
    line-height: ${theme.typography.sectionTitle.serif.lhSm};
    text-align: center;

    @media screen and (min-width: 300px) {
      display: flex;
      align-items: center;
      white-space: nowrap;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 0;
        border-bottom: 1px solid ${theme.colors.lightBorder};
      }

      &::before {
        margin-right: 15px;
      }

      &::after {
        margin-left: 15px;
      }
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      /* margin-bottom: ${theme.typography.sectionTitle.serif.mbLg}; */
      font-size: ${theme.typography.sectionTitle.serif.fsLg};
      line-height: ${theme.typography.sectionTitle.serif.lhLg};

      &::before {
        margin-right: 48px;
      }

      &::after {
        margin-left: 48px;
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .section-label {
    font-family: ${theme.typography.sansSerif};
    margin-bottom: ${theme.typography.sectionLabel.mbSm};
    font-family: ${theme.typography.sansSerif};
    font-size: ${theme.typography.sectionLabel.fsSm};
    line-height: ${theme.typography.sectionLabel.lhSm};
    font-weight: ${theme.typography.sectionLabel.fw};

    @media screen and (min-width: ${theme.responsive.large}) {
      margin-bottom: ${theme.typography.sectionLabel.mbLg};
      font-size: ${theme.typography.sectionLabel.fsLg};
      line-height: ${theme.typography.sectionLabel.lhLg};
    }
  }

  .label {
    margin: 0;
    font-size: ${theme.typography.label.fsSm};
    line-height: ${theme.typography.label.lhSm};
    text-transform: uppercase;
    letter-spacing: ${theme.typography.label.lsSm};
    font-weight: ${theme.typography.label.fw};
    color: ${theme.colors.primary};

    @media screen and (min-width: ${theme.responsive.large}) {
      font-size: ${theme.typography.label.fsLg};
      line-height: ${theme.typography.label.lhLg};
      letter-spacing: ${theme.typography.label.lsLg};
    }
  }

  figcaption,
  .detail {
    margin: 0;
    font-size: ${theme.typography.detail.fsSm};
    line-height: ${theme.typography.detail.lhSm};
    /* font-weight: ${theme.typography.detail.fw}; */
    color: currentColor;
  }

  .caption {
    margin: 0;
    font-size: ${theme.typography.caption.fsSm};
    line-height: ${theme.typography.caption.lhSm};
    /* font-weight: ${theme.typography.caption.fw}; */
    color: ${theme.colors.grayText};
  }

  .note-text {
    font-size: 12px;
    line-height: 16px;
  }

  .after-parallax-hero {
    position: relative;
    z-index: 2;
    background-color: white;
  }

  .top-artist-profile-container .body-content {
    max-width: ${theme.containerWidths.smallMedium};
    margin-left: auto;
    margin-right: auto;
  }

  .honor-roll-container {
    .headline--intro {
      margin-bottom: 24px;
    }
  }

  .article-container {
    max-width: ${theme.containerWidths.smallMedium};
    padding-top: var(--section-padding);
    margin-left: auto;
    margin-right: auto;

    hr {
      margin: 30px 0;
    }

    figure {
      margin-top: 24px; // Match paragraph margin
      margin-bottom: 24px; // Match paragraph margin
    }

    p > figure {
      margin-top: 0; // Don't add margin if already within a paragraph
      margin-bottom: 0; // Don't add margin if already within a paragraph
    }

    figcaption {
      margin-top: 15px;
    }

    .gatsby-resp-image-wrapper {
      max-width: none !important;
      /* margin-left: calc(var(--site-x-padding) * -1) !important; */
      /* margin-right: calc(var(--site-x-padding) * -1) !important; */
    }

    &.article--honor-roll {
      .section,
      section {
        .headline--intro {
          color: inherit;
        }

        + .section,
        + section {
          margin-top: 20px;
        }
      }
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      padding-top: calc(var(--section-padding) * 0.75);
    }
  }

  .article--honor-roll {
    .headline--intro {
      max-width: none;
    }

    .body-content {
      + section.wrap {
        margin-top: 0;
      }
    }
  }

  // HELPERS
  .accent-color {
    color: ${theme.colors.primary};
  }

  .page--artist-reunion > div,
  .artist-reunion {
    .accent-color,
    .label,
    a {
      color: ${theme.colors.accentReunion};
    }
  }

  .page--incentive-trip,
  .incentive-trip {
    .accent-color,
    .label,
    a {
      color: ${theme.colors.accentIncentiveTrip};
    }
  }

  .page--virtual-meeting,
  .virtual-meeting {
    .accent-color,
    .label,
    a {
      color: ${theme.colors.accentVirtualMeeting};
    }
  }

  ul.accent-bullets {
    list-style: none;

    li {
      &::before {
        background-color: ${theme.colors.primary};

        .page--artist-reunion & {
          background-color: ${theme.colors.accentReunion};
        }

        .page--incentive-trip & {
          background-color: ${theme.colors.accentIncentiveTrip};
        }

        .page--virtual-meeting & {
          background-color: ${theme.colors.accentVirtualMeeting};
        }
      }
    }
  }

  .show-lg-up {
    @media screen and (max-width: ${theme.responsive.underLarge}) {
      display: none !important;
    }
  }

  .hide-lg-up {
    @media screen and (min-width: ${theme.responsive.large}) {
      display: none !important;
    }
  }

  .show-md-up {
    @media screen and (max-width: ${theme.responsive.underMedium}) {
      display: none !important;
    }
  }

  .show-sm-up {
    @media screen and (max-width: ${theme.responsive.underSmall}) {
      display: none !important;
    }
  }

  .hide-sm-up {
    @media screen and (min-width: ${theme.responsive.small}) {
      display: none !important;
    }
  }

  .wrap {
    padding-left: var(--site-x-padding);
    padding-right: var(--site-x-padding);
  }

  .m0 {
    margin: 0;
  }

  .flex {
    display: flex;
  }

  .inline-block {
    display: inline-block;
  }

  .truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .center-align {
    text-align: center;
  }

  .left-align {
    text-align: left;
  }

  .text-italic {
    font-style: italic;
  }

  .medium-weight {
    font-weight: 500;
  }

  .semi-bold-weight {
    font-weight: 600;
  }

  .fill--nearly-white {
    background-color: ${theme.colors.nearlyWhite};
  }

  .wkthcd {
    -webkit-tap-highlight-color: ${theme.colors.webkitTapHighlight};
  }

  .wkthct {
    -webkit-tap-highlight-color: transparent;
  }

  // VENDOR
  .plyr {
    .plyr__controls-container {
      opacity: 1;
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0,0,0,0.04), rgba(0,0,0,0.55));
      transition: opacity 200ms ease;
    }
    &.plyr--hide-controls {
      .plyr__controls-container {
        opacity: 0;
        transition: opacity 200ms ease;
      }
    }
    .plyr__controls {
      padding: 0 !important;
      margin: 5px;
      background: transparent !important;
      transform: translateY(0) !important;
    }

    input[type=range] {
      cursor: pointer;
    }

    .plyr__audio-controls-wrapper {
      position: relative;

      .plyr__volume {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        top: -65px;
        left: -7px;
        width: 110px;
        max-width: none; // override
        height: 32px; // equal to mute button size
        padding: 2px 10px 2px 5px; // give extra room for user to go outside the slider
        transform: rotate(270deg) translateY(-100%);

        input[type='range'] {
          width: 0;
          margin: 0; // override
          transition: width 220ms ease 50ms;
        }
      }
      @media not all and (pointer: coarse) {
        &:hover {
          .plyr__volume {
            opacity: 1;
            pointer-events: initial;

            input[type='range'] {
              width: 85px; // parent height minus padding
            }
          }

          .plyr__control {
            opacity: 1 !important;
          }
        }
      }
    }
    &.plyr--is-touch {
      .plyr__audio-controls-wrapper {
        .plyr__volume {
          opacity: 1;
          pointer-events: initial;

          input[type='range'] {
            width: 85px; // parent height minus padding
          }

          .plyr__control {
            opacity: 1 !important;
          }
        }
      }
    }

    &.plyr--video {

      input[type="range"] {
        color: white;

        &::-webkit-slider-thumb {
          width: 5px;
          height: 5px;
          margin-top: 0;
          margin-left: -1px;
          background-color: white;
          box-shadow: none;
          border-radius: 50%;
          cursor: grab;
        }
        &::-moz-range-thumb {
          width: 5px;
          height: 5px;
          margin-top: 0;
          margin-left: -1px;
          background-color: white;
          box-shadow: none;
          border-radius: 50%;
          cursor: grab;
        }
        &::-ms-thumb {
          width: 5px;
          height: 5px;
          margin-top: 0;
          margin-left: -1px;
          background-color: white;
          box-shadow: none;
          border-radius: 50%;
          cursor: grab;
        }

        @media not all and (pointer: coarse) {
          &:hover {
            &::-webkit-slider-thumb {
              width: 11px;
              height: 11px;
              margin-top: -2.75px;
              margin-left: 0;
              background-color: white;
              box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
            }
            &::-moz-range-thumb {
              width: 11px;
              height: 11px;
              margin-top: -2.75px;
              margin-left: 0;
              background-color: white;
              box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
            }
            &::-ms-thumb {
              width: 11px;
              height: 11px;
              margin-top: -2.75px;
              margin-left: 0;
              background-color: white;
              box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
            }
          }
        }
      }
    }

    &.plyr--is-touch {
      input[type="range"] {
        &::-webkit-slider-thumb {
          width: 11px;
          height: 11px;
          margin-top: -2.75px;
          margin-left: 0;
          background-color: white;
          box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
        }
        &::-moz-range-thumb {
          width: 11px;
          height: 11px;
          margin-top: -2.75px;
          margin-left: 0;
          background-color: white;
          box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
        }
        &::-ms-thumb {
          width: 11px;
          height: 11px;
          margin-top: -2.75px;
          margin-left: 0;
          background-color: white;
          box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
        }
      }
    }

    &.plyr--video,
    .plyr__time {
      font-family: ${theme.typography.sansSerif};
      font-size: 12px;
    }
    .plyr__time {
      margin-right: 10px;

      ~ .plyr__time {
        margin-left: 10px;
        margin-right: 0;
      }
    }
    .plyr__controls {
      @media not all and (pointer: coarse) {
        &:hover {
          .plyr__control {
            &:hover {
              opacity: 1;
            }
            &:not(:hover) {
              opacity: 0.5
            }
          }
        }
      }
    }
    .plyr__control {
      &[data-plyr="fullscreen"] {
          margin-left: 10px;
      }

      .plyr__tooltip {
        opacity: 0 !important;
      }
    }

    &.plyr--is-ios {
      .plyr__control {
        &[data-plyr="fullscreen"] {
          display: none !important;
        }
      }
    }

    .plyr__progress {
      flex: 1 0;
    }
    &.plyr--video .plyr__control.plyr__tab-focus,
    &.plyr--video .plyr__control:hover,
    &.plyr--video .plyr__control[aria-expanded='true'] {
      background: transparent;
      box-shadow: none;
    }
    /* &.plyr--video .plyr__control.plyr__control--overlaid.plyr__tab-focus,
    &.plyr--video .plyr__control.plyr__control--overlaid:hover,
    &.plyr--video .plyr__control.plyr__control--overlaid:focus,
    &.plyr--video .plyr__control.plyr__control--overlaid[aria-expanded='true'] {
      background-color: rgba(255, 255, 255, 0.45);
    } */
    &.plyr--playing {
      @media not all and (pointer: coarse) {
        &:hover {
          .plyr__control--overlaid {
            opacity: 1;
            visibility: initial;
          }
        }
      }
      &.plyr--hide-controls {
        cursor: none;

        .plyr__control--overlaid {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &.plyr--is-touch {
      &.plyr--playing .plyr__control--overlaid {
        opacity: 1;
        visibility: initial;
      }
    }
    .plyr__control--overlaid {
      background: transparent !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid ${theme.colors.lightBorder};

      svg {
        width: 17%;
        height: 17%;
        min-width: 50px;
        min-height: 50px;
        max-width: 85px;
        max-height: 85px;
        transition: opacity 200ms ease;

        &.icon--pressed {
          min-width: 15px;
          min-height: 15px;
          max-width: 40px;
          max-height: 40px;
        }
      }

      @media not all and (pointer: coarse) {
        svg {
          opacity: 0.85;
        }

        &:hover {
          svg {
            opacity: 1;
          }
        }
      }
    }
    .plyr__video-wrapper {
      background: transparent;
    }
    &.plyr--video {
      background: transparent;
    }
    .plyr__control.plyr__tab-focus {
      box-shadow: none;
    }
    &.plyr--stopped {
      .plyr__controls-container {
        background: transparent;
      }

      .plyr__controls {
        opacity: 0;
        pointer-events: none;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
      }
    }
    .plyr--full-ui input[type='range'] {
      color: ${theme.colors.primary};
    }
    .plyr__poster {
      background-color: ${theme.colors.nearlyWhite};
    }
  }
`
export default GlobalStyle
