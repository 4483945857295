import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import { Link } from 'gatsby'
import React, { useContext, useEffect, useRef, useState } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import { PageContext } from '../context/ApplicationState'
// @ts-ignore
import Logo from '../images/logo.svg'
import theme from '../styles/theme'

const logo = {
  widthSm: '94px',
  widthSm_subNav: '128px',
  widthLg: '120px',
}
const mobileTransformTiming = 180
const mobileOpacityTiming = 200
const mobileToggleTransformTiming = 120
const mobileToggleOpacityTiming = 40
const mobileToggleColorTiming = 80
const stickyTiming = 120

const setupStickyClass = 'setup-sticky-menu'
const isStuckClass = 'show-sticky-menu'
const noTransitionClass = 'no-transition'

const Nav = styled.nav`
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 3 !important;
  height: ${theme.navMenu.heightSm};
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  transition: background-color 180ms ease;

  .has-light-header & {
    border-color: ${theme.colors.lightBorder};
  }

  .has-hero-section & {
    border-color: transparent;
  }

  @media screen and (min-width: ${theme.responsive.large}) {
    align-items: center;
    height: ${theme.navMenu.heightLg};
  }

  .brand-link,
  .brand--mobile-nav a {
    -webkit-tap-highlight-color: transparent;
  }

  > .brand-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;

    svg {
      width: ${logo.widthSm};
    }

    .has-light-header & {
      color: ${theme.colors.darkText};
    }
  }

  &.${setupStickyClass} {
    background-color: white;
    position: fixed !important;
    transform: translateY(-100%);
    transition: ${`background-color 180ms ease, transform ${stickyTiming}ms ease`};

    &.${isStuckClass} {
      transform: translateY(0);
      transition: ${`background-color 180ms ease, transform ${stickyTiming}ms ease`};
      border-color: ${theme.colors.lightBorder};

      a {
        color: ${theme.colors.darkText} !important;
      }
    }
  }

  &.${noTransitionClass} {
    transition-duration: 0ms;
  }

  .menu-active & {
    transform: translateY(0) !important;
  }

  .div-100vh {
    flex: 1 0;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;

    .menu-active & {
      pointer-events: initial;
    }
  }

  @media screen and (min-width: ${theme.responsive.large}) {
    > .brand-link {
      display: none;
    }

    .div-100vh {
      position: relative;
      pointer-events: initial;
    }
  }
`

const MenuToggle = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: ${theme.navMenu.heightSm};
  width: calc(var(--site-x-padding) + 17px + var(--site-x-padding));
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  span {
    width: 17px;
    height: 1px;
    display: block;
    position: relative;
    margin: 3.5px auto;
    background-color: white;
    transform: translateY(0) rotate(0deg);
    transition: transform ${mobileToggleTransformTiming}ms ease,
      opacity ${mobileToggleOpacityTiming}ms ease,
      background-color ${mobileToggleColorTiming}ms ease
        ${mobileTransformTiming - mobileToggleColorTiming}ms;

    .has-light-header &,
    .${setupStickyClass} & {
      background-color: ${theme.colors.darkText};
    }
  }

  .menu-active & {
    position: fixed;
    height: calc(${theme.navMenu.heightSm} + 10px);
    width: calc(${theme.navMenu.heightSm} + 10px);

    span {
      width: 19px;
      background-color: ${theme.colors.darkText};
      transform: translateY(4px) rotate(45deg);

      + span {
        transform: translateY(-4px) rotate(-45deg);
      }
    }
  }

  @media screen and (min-width: ${theme.responsive.large}) {
    display: none;
  }
`

const PosedMenuLinks = posed.ul({
  on: {
    delayChildren: 40,
    staggerChildren: 30,
  },
  off: {},
})

const PosedItem = posed.li({
  on: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'easeOut',
      duration: 150,
    },
  },
  off: {
    opacity: 0,
    x: -40,
    transition: {
      x: { delay: 50 },
      opacity: { delay: 50 },
    },
  },
})

const MenuLinks = styled(PosedMenuLinks)`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 242px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  left: 0;
  top: 0;
  z-index: 4;
  padding: 50px 0 0;
  background-color: white;
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  pointer-events: none;
  transition: transform ${mobileTransformTiming * 1.8}ms ease,
    opacity ${mobileOpacityTiming * 1.8}ms ease
      ${mobileTransformTiming * 0.75}ms;

  .menu-active & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: initial;
    transition: transform ${mobileTransformTiming}ms ease,
      opacity ${mobileOpacityTiming}ms ease;
  }

  @media screen and (min-width: ${theme.responsive.large}) {
    // Do not apply Pose effects if not mobile menu
    li {
      transform: translateX(0) !important;
      transition: none !important;
      opacity: 1 !important;

      a {
        transition: 0ms !important;
      }
    }
    // End Pose overrides

    pointer-events: initial;
    opacity: 1;
    box-shadow: none;
    transition: transform 0ms, opacity 0ms;
    background-color: transparent;

    flex: 1;
    position: relative;
    top: 0;
    width: 100%;
    max-width: ${theme.siteMaxWidth};
    height: inherit;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: -5px; // Account for link padding 25px vs siteXPadding 20px
    margin-right: -5px; // Account for link padding 25px vs siteXPadding 20px
    align-items: center;
    padding: 0;
    transform: none;

    .brand-link {
      height: 100%;
      flex-grow: 1;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 240px;
        height: 100%;
        top: 0;
        left: 50%;
        padding-top: 0;
        padding-bottom: 0;
        transform: translateX(-50%);
        text-align: center;

        svg {
          width: ${logo.widthLg};
        }
      }
    }
  }

  li {
    flex-shrink: 0;
    display: flex;

    a {
      display: block;
      width: 100%;
      padding: 15px 25px;
      color: ${theme.colors.darkText};
      text-decoration: none;
      font-size: 30px;
      line-height: 30px;
      font-weight: 300;
    }

    &.brand-link {
      display: none;
    }

    @media screen and (min-width: ${theme.responsive.large}) {
      a {
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        color: white;
        /* padding: 15px 18px; */
        transition: color 160ms ease;

        @media not all and (pointer: coarse) {
          &:hover {
            .has-light-header & {
              color: ${theme.colors.darkText};
            }
          }
        }

        .has-light-header & {
          color: ${theme.colors.darkText};
        }
      }

      &.brand-link {
        display: block;
        margin-left: 20px; // Center logo to site, not spacing of links
      }
    }
  }

  .social-link {
    display: none;

    @media screen and (min-width: ${theme.responsive.large}) {
      display: flex;
    }

    a {
      padding-left: 10px;
      padding-right: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .brand--mobile-nav {
    margin: 20px auto 5px;

    a {
      text-align: center;

      svg {
        width: ${logo.widthSm_subNav};
      }
    }

    @media screen and (min-height: 550px) {
      margin: auto auto 10px;
    }
  }

  .additional-links--mobile-nav {
    &::before {
      content: '';
      display: inline-block;
      width: 25px;
      height: 0;
      margin: 15px 25px;
      border-bottom: 2px solid ${theme.colors.primary};
    }

    li {
      a {
        padding: 11px 25px;
        font-size: 15px;
        line-height: 15px;
        font-weight: 500;
      }
    }
  }
`

const SiteNav = () => {
  const [visibility, setVisibility] = useState(false)
  const { pageState } = useContext(PageContext)
  const hasHeroSection = pageState.hasHeroSection
  const hasStickySiteNav = pageState.hasStickySiteNav

  const overflowRef = useRef(null)
  const ref = useRef(null)

  const domEvents = [
    'resize',
    'scroll',
    // 'touchstart',
    'touchmove',
    // 'touchend',
    'pageshow',
    'load',
  ]

  const handleEvents = lastScrollPos => () => {
    // Set an explicit CSS height for the mobile menu because 100vh doesn't work how we want with iOS's Safari browser
    // chrome that overlays the page (the viewport gets "cut off" by the browser's chrome because the chrome isn't
    // calculated away from the viewport height)
    if (
      overflowRef &&
      overflowRef.current &&
      window.matchMedia(`(max-width: ${theme.responsive.underLarge})`).matches
    ) {
      overflowRef.current.style.height = `${window.innerHeight}px`
    } else if (overflowRef && overflowRef.current) {
      overflowRef.current.style.height = null
    }

    // Setup the site nav to become fixed (stuck) to the top but only after a certain point down the page and only when
    // the page is reverse scrolled (scrolled up)
    const scrollPos = window.scrollY
    const scrollOffset = -8
    const scrollDifference = scrollPos - lastScrollPos
    const stickFromTop = window.matchMedia(
      `(max-width: ${theme.responsive.large})`
    ).matches
      ? 800
      : 1200

    if (ref && ref.current) {
      const navClassList = ref.current.classList
      // Only certain pages will use the sticky nav
      if (
        document.querySelector('body').classList.contains('has-sticky-site-nav')
      ) {
        // Undo any sticky behavior when at the top of a page
        if (scrollPos <= 0) {
          navClassList.remove(setupStickyClass, isStuckClass, noTransitionClass)
        }
        // The sticky logic shouldn't be implemented/changed if the mobile nav is active (visible)
        if (!visibility) {
          // The user shouldn't see the nav being setup for the fixed/stuck behavior so it needs to not have the CSS
          // transition effect's duration when first setup
          if (
            scrollPos < stickFromTop &&
            scrollPos > ref.current.offsetHeight &&
            !navClassList.contains(isStuckClass)
          ) {
            navClassList.add(noTransitionClass)
            navClassList.remove(setupStickyClass)
          }

          // After the nav has been setup to be able to stick, let the CSS transition show as usual
          if (scrollPos > stickFromTop) {
            setTimeout(() => {
              navClassList.remove(noTransitionClass)
            }, stickyTiming)
            navClassList.add(setupStickyClass)

            // Show the sticky nav on reverse scroll but not immediately. i.e. if the page is scrolled very slowly, the
            // nav shouldn't show. The scrollOffset value determines the minimum reverse scroll "speed"
            if (scrollDifference < scrollOffset) {
              navClassList.add(isStuckClass)
            }
            // Hide the nav whenever the page is being scrolled forward (down)
            if (scrollDifference > 0) {
              navClassList.remove(isStuckClass)
            }
          }
        }
      } else {
        navClassList.remove(setupStickyClass, isStuckClass, noTransitionClass)
      }
      lastScrollPos = scrollPos <= 0 ? 0 : scrollPos
    }
  }

  useEffect(() => {
    const currentPageHeroSection = hasHeroSection
    if (currentPageHeroSection) {
      document.querySelector('body').classList.add('has-hero-section')
    } else {
      document.querySelector('body').classList.remove('has-hero-section')
    }
    const currentPageStickyNav = hasStickySiteNav
    if (currentPageStickyNav) {
      document.querySelector('body').classList.add('has-sticky-site-nav')
    } else {
      document.querySelector('body').classList.remove('has-sticky-site-nav')
    }
    if (
      overflowRef &&
      overflowRef.current &&
      window.matchMedia(`(max-width: ${theme.responsive.underLarge})`).matches
    ) {
      overflowRef.current.style.height = `${window.innerHeight}px`
    } else if (overflowRef && overflowRef.current) {
      overflowRef.current.style.height = null
    }

    function scrollLocking() {
      if (visibility) {
        disableBodyScroll(overflowRef.current)
        document.querySelector('body').classList.add('menu-active')
      } else {
        enableBodyScroll(overflowRef.current)
        document.querySelector('body').classList.remove('menu-active')
      }
    }

    scrollLocking()

    const lastScrollPos = window.scrollY

    function setupEventsHandler() {
      domEvents.forEach(event => {
        window.addEventListener(event, handleEvents(lastScrollPos), {
          capture: true,
          passive: true,
        })
      })
      handleEvents()
    }

    setupEventsHandler()

    return function cleanup() {
      clearAllBodyScrollLocks()

      domEvents.forEach(event => {
        window.removeEventListener(event, handleEvents)
      })
    }
  }, [visibility, pageState])

  const closeMenu = () => {
    setVisibility(false)
  }

  const toggleMenu = () => {
    setVisibility(!visibility)
  }

  return (
    <Nav ref={ref}>
      <div className="modal-overlay" onClick={() => closeMenu()} />
      <MenuToggle onClick={() => toggleMenu()}>
        <span />
        <span />
      </MenuToggle>
      <Link className="brand-link" to="/">
        <Logo />
      </Link>
      <MenuLinks
        className="site-nav div-100vh"
        ref={overflowRef}
        pose={visibility ? 'on' : 'off'}
      >
        <PosedItem onClick={() => closeMenu()}>
          <Link to="/news/">
            <span>News</span>
          </Link>
        </PosedItem>
        <PosedItem onClick={() => closeMenu()}>
          <Link to="/events/">
            <span>Events</span>
          </Link>
        </PosedItem>
        <PosedItem onClick={() => closeMenu()}>
          <Link to="/rewards/">
            <span>Rewards</span>
          </Link>
        </PosedItem>
        <PosedItem onClick={() => closeMenu()}>
          <Link to="/recognition/honor-roll/">
            <span>Recognition</span>
          </Link>
        </PosedItem>
        <li className="brand-link" onClick={() => closeMenu()}>
          <Link to="/">
            <Logo className="brand-logo" />
          </Link>
        </li>
        <PosedItem onClick={() => closeMenu()}>
          <Link to="/training/">
            <span>Training</span>
          </Link>
        </PosedItem>
        <PosedItem onClick={() => closeMenu()}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dropbox.com/scl/fo/shlq0w3tnvjtmvzved2y8/AGvu14sz1uGmEYQ30ndTymc?rlkey=kssvufneer3tgbyzisrzbtmbf&st=vajnri0q&dl=0"
          >
            <span>Resources ↗</span>
          </a>
        </PosedItem>
        <PosedItem onClick={() => closeMenu()}>
          <Link to="/support/">
            <span>Support</span>
          </Link>
        </PosedItem>
        {/* <li className="social-link">
            <a
              href="https://www.facebook.com/groups/1829586323964956/"
              target="_blank"
              rel="noopener noreferrer"
              >
              <Facebook width="8px" />
              </a>
              </li>
              <li className="social-link">
              <a href="//www.instagram.com/seintartists/" target="_blank" rel="noopener noreferrer">
              <Instagram width="16px" />
            </a>
          </li> */}
        {/* <li>
          <ul className="additional-links--mobile-nav hide-lg-up">
            <li>
              <a href="https://www.seintofficial.com/en/login/">Back Office</a>
            </li>
            <li>
              <a href="https://www.facebook.com/groups/1829586323964956/">
                Facebook Group
              </a>
            </li>
          </ul>
        </li> */}
        <li
          className="brand--mobile-nav hide-lg-up"
          onClick={() => closeMenu()}
        >
          <Link to="/">
            <Logo className="brand-logo" />
          </Link>
        </li>
      </MenuLinks>
    </Nav>
  )
}

export default SiteNav
