import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import VirtualMeetingLogo from '../images/virtual-meeting-logo.svg'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  text-align: center;
  color: white;

  h1 {
    font-size: 36px;
    line-height: 38px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  h3 {
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 18px;
    margin-top: 10px;
  }

  a {
    color: white;
    margin-top: 24px;
  }

  svg {
    width: 200px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    h1 {
      font-size: 50px;
      line-height: 50px;
    }

    h3 {
      font-size: 16px;
      line-height: 16px;
      margin-top: 15px
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    h1 {
      font-size: 70px;
      line-height: 70px;
    }

    h3 {
      font-size: 18px;
      line-height: 18px;
      margin-top: 25px;

      + p {
        font-size: 17px;
        line-height: 18px;
      }
    }

    svg {
      width: 350px;
    }
  }
`

const Detail = styled.p`
  font-family: 'Helvetica Neue', sans-serif;
  letter-spacing: 1px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
  color: inherit;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    bottom: 24px;
    font-size: 17px;
    line-height: 18px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    bottom: 40px;
    font-size: 22px;
    line-height: 22px;
  }
`

const VirtualMeetingHeader = props => (
  <Wrapper {...props}>
    <Link to="/events/virtual-meeting/">
      <VirtualMeetingLogo />
    </Link>
    {/*
    <Detail>02.24</Detail>
    */}
  </Wrapper>
)

export default VirtualMeetingHeader
