/* eslint-disable react/display-name */
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import ArrowUp from '../images/arrow--caret-up.svg'

const Outer = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${props => props.theme.stickyNav.heightSm};
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.lightBorder};
  transition: height 150ms ease-in-out;

  .fill--nearly-white & {
    border-color: transparent;
  }

  .scroll-indicator {
    position: absolute;
    top: 0;
    bottom: -1px;
    right: 0;
    z-index: 1;
    width: 30px;
    height: calc(100% + 1px);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white 15px,
      white
    );
    color: ${props => props.theme.colors.lightGray};
    opacity: 0;
    transition: opacity 200ms ease-in;

    &::after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      right: 0;
      width: 30px;
      z-index: 1;
      height: 1px;
      background: linear-gradient(
        to right,
        ${props => rgba(props.theme.colors.lightBorder, 0)},
        ${props => props.theme.colors.lightBorder} 20px,
        ${props => props.theme.colors.lightBorder}
      );
    }

    svg {
      transform: rotate(90deg);
      margin-left: 5px;
    }

    &.show {
      opacity: 1;
    }
  }

  .h-overflow-wrapper {
    position: relative;
    width: max-content;
    max-width: 100%;
    height: ${props => props.theme.stickyNav.heightSm};
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    text-align: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    > div {
      display: flex;
      flex-wrap: nowrap;
      /* align-items: flex-end; */
      height: 100%;

      a,
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        white-space: nowrap;
        padding: 0 11px;
        font-size: ${props => props.theme.stickyNav.fsSm};
        line-height: ${props => props.theme.stickyNav.lhSm};
        color: ${props => props.theme.colors.lightGray};
        text-decoration: none;
        font-weight: 500;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          // calc left (11) + right (11) padding on <a> minus 2
          width: calc(100% - 20px);
          height: 2px;
          bottom: 0;
          left: 10px; // match left padding on <a> minus 1
          background-color: transparent;
        }

        &:first-child {
          padding-left: var(--site-x-padding);

          &::after {
            left: 19px;
          }
        }

        &:last-child {
          padding-right: var(--site-x-padding);
        }

        &:first-child,
        &:last-child {
          &::after {
            // calc left (20 or 11) + right (11 or 20) padding on <a> minus 2
            width: calc(100% - 29px);
          }
        }

        @media not all and (pointer: coarse) {
          &:hover {
            color: ${props => props.theme.colors.darkText};
            font-weight: 600;
            letter-spacing: -0.1px;
          }
        }

        &.active-page-link {
          color: ${props => props.theme.colors.darkText};
          font-weight: 600;

          @media not all and (pointer: coarse) {
            &:hover {
              letter-spacing: unset;
            }
          }

          &::after {
            background-color: ${props => props.theme.colors.primary};

            .page--artist-reunion & {
              background-color: ${props => props.theme.colors.accentReunion};
            }

            .page--incentive-trip & {
              background-color: ${props => props.theme.colors.accentIncentiveTrip};
            }

            .page--virtual-meeting & {
              background-color: ${props => props.theme.colors.accentVirtualMeeting};
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    height: ${props => props.theme.stickyNav.heightLg};

    .h-overflow-wrapper {
      height: ${props => props.theme.stickyNav.heightLg};

      > div {
        height: 100%;
        padding-left: 0;
        padding-right: 0;

        a,
        button {
          font-size: ${props => props.theme.stickyNav.fsLg};
          line-height: ${props => props.theme.stickyNav.lhLg};

          &,
          &:first-child,
          &:last-child {
            padding: 20px;

            &::after {
              // calc left + right padding on <a> minus 2
              width: calc(100% - 38px);
              left: 19px; // match left padding on <a> minus 1
            }
          }
        }
      }
    }
  }

  &.is-stuck {
    position: fixed;

    &.is-past-bottom {
      position: absolute;
      top: initial;
      bottom: 0;
    }

    &.no-animate {
      transition: all 0s !important;
    }
  }
`

// @ts-ignore
const StickyNav = ({ children }, ref) => (
  <Outer ref={ref} className="sticky-nav">
    <div className="h-overflow-wrapper">
      <div>{children}</div>
    </div>
    <span className="scroll-indicator">
      <ArrowUp width="14px" />
    </span>
  </Outer>
)

export default React.forwardRef(StickyNav)
