import { graphql } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import Footer from '../components/Footer'
import { isLoggedIn } from '../services/auth'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Login from './Login'

const Layout = ({ children, ...props }) => {
  const domEvents = ['resize', 'pageshow', 'load']

  const handleEvents = () => {
    if (ref && ref.current) {
      ref.current.style.minHeight = `${window.innerHeight}px`
    }
  }

  const ref = useRef(null)

  useEffect(() => {
    function setupEventsHandler() {
      domEvents.forEach((event) => {
        window.addEventListener(event, handleEvents, {
          capture: true,
          passive: true,
        })
      })
      handleEvents()
    }

    setupEventsHandler()

    return function cleanup() {
      domEvents.forEach((event) => {
        window.removeEventListener(event, handleEvents)
      })
    }
  })

  const simpleKebabCase = (string) =>
    string.split(/[_\s]/).join('-').toLowerCase()

  return (
    <>
      <ThemeProvider theme={theme}>
        <>
          <Helmet>
            <title>{config.siteTitle}</title>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1"
            />
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="default"
            />
          </Helmet>
          <div
            ref={ref}
            className={`site-root${
              props.pageName ? ` page--${simpleKebabCase(props.pageName)}` : ''
            }`}
          >
            {isLoggedIn() ? (
              <>
                {children}
                <Footer />
              </>
            ) : (
              <Login />
            )}
          </div>
        </>
      </ThemeProvider>
      <GlobalStyle />
    </>
  )
}

export const HeroImageSettings = graphql`
  fragment HeroImageSettings on ContentfulPageContext {
    heroImageMobile {
      fluid(
        sizes: "(max-width: 500px) 225vw, (max-width: 700px) 190vw,"
        quality: 75
      ) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroImageDesktop {
      fluid(
        sizes: "(max-width: 1100px) 150vw, (max-width: 1440px) 120vw, 100vw,"
        quality: 95
      ) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`

export const ArticleCardImageSettings = graphql`
  fragment ArticleCardImageSettings on ContentfulArticle {
    heroImage {
      title
      fluid(
        sizes: "(max-width: 767px) calc(100vw - 15px - 10px - 10px - 15px), (max-width: 1023px) calc(50vw - 15px - 12px), (max-width: 1069px) calc(33vw - 15px - 12px), 365px"
        quality: 75
      ) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

export const ArticlesQuery = graphql`
  fragment ArticlesQuery on ContentfulArticle {
    id
    slug
    title
    intro
    publishDate
    category {
      title
    }
    ...ArticleCardImageSettings
    videoHeader {
      src
    }
  }
`

export const RelatedArticleImageSettings = graphql`
  fragment RelatedArticleImageSettings on ContentfulArticle {
    heroImage {
      title
      fluid(sizes: "(max-width: 1023px) 40vw, 300px", quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

export const TopArtistProfileImageSettings = graphql`
  fragment TopArtistProfileImageSettings on ContentfulTopArtistProfile {
    heroImage {
      title
      fluid(
        sizes: "(max-width: 767px) calc(100vw - 15px - 10px - 10px - 15px), (max-width: 1023px) calc(50vw - 15px - 12px), (max-width: 1069px) calc(33vw - 15px - 12px), 365px"
        quality: 75
      ) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

export default Layout
