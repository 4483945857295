import React from 'react'
import styled from 'styled-components'
import LoadingSpinner from '../components/LoadingSpinner'
import { handleLogin } from '../services/auth'

const Form = styled.form`
  flex-grow: 0 !important; // override default flex-grow: 1
  max-width: 500px;

  &.has-error {
    input,
    select,
    textarea {
      border-color: ${(props) => props.theme.colors.errorRed};
    }
  }

  .headline--large {
    text-align: center;
  }

  input,
  button {
    width: 100%;
  }

  label + input,
  button[type='submit'] {
    margin-top: 8px;
  }

  .text-danger {
    margin: 5px 0 0;
    line-height: 1.3;
    color: ${(props) => props.theme.colors.errorRed};
  }
`

const Login = () => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [loginError, setLoginError] = React.useState('')
  const [loginPending, setLoginPending] = React.useState(false)

  const handleUsername = (event) => {
    setUsername(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoginPending(true)
    const loginSuccessful = await handleLogin({ username, password })
    setLoginPending(false)

    if (loginSuccessful) {
      window.location.reload()
    } else {
      setLoginError('Login unsuccessful. Please try again.')
    }
  }

  return (
    <Form
      className={`section wrap${loginError ? ' has-error' : ''}`}
      method="post"
      onSubmit={(event) => {
        handleSubmit(event)
      }}
    >
      <h2 className="headline--large">Artist Login</h2>
      <label className="visually-hidden">Username</label>
      <input
        type="text"
        placeholder="Username"
        name="username"
        onChange={handleUsername}
        value={username}
        readOnly={loginPending}
      />
      <label className="visually-hidden">Password</label>
      <input
        type="password"
        placeholder="Password"
        name="password"
        onChange={handlePassword}
        readOnly={loginPending}
      />
      {loginError && <p className="text-danger">{loginError}</p>}
      <button className="btn--submit" type="submit" disabled={loginPending}>
        Log In
      </button>
      {loginPending === true && (
        <LoadingSpinner
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
          }}
        />
      )}
    </Form>
  )
}

export default Login
