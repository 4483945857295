import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { PageContext } from '../context/ApplicationState'
import IncentiveTripHeader from './IncentiveTripHeader'
import Layout from './Layout'
import PageHeader from './PageHeader'
import SEO from './SEO'
import StickyContainer from './StickyContainer'

const IncentiveTripLayout = ({ location, children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState({
      ...pageState,
      pageName: 'incentive-trip',
      hasStickySiteNav: false,
      hasHeroSection: true,
    })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "Default Incentive Trip" } }) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
    }
  `)

  const heroImageMobile =
    data.allContentfulPageContext.edges[0].node.heroImageMobile
  const heroImageDesktop =
    data.allContentfulPageContext.edges[0].node.heroImageDesktop

  return (
    <Layout {...props}>
      <SEO
        shareImagePath="/images/share-incentive.jpg"
        shareImageHeight={628}
      />
      <PageHeader
        page="incentive-trip"
        imageMobile={heroImageMobile}
        imageDesktop={heroImageDesktop}
      >
        <IncentiveTripHeader />
      </PageHeader>
      <StickyContainer
        stickyNavLinks={
          <>
            <Link
              to="/events/incentive-trip/"
              activeClassName="active-page-link"
            >
              Past Trips
            </Link>
          </>
        }
      >
        {children}
      </StickyContainer>
    </Layout>
  )
}

export default IncentiveTripLayout
