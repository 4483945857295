import { darken } from 'polished'
import React from 'react'
import styled from 'styled-components'
import theme from '../styles/theme'

const animationTiming = 1200
const spinnerSize = 38

const Outer = styled.div`
  text-align: center;
  color: ${theme.colors.lightBorder};

  p {
    position: relative;
    /* top: 100%; */
    margin-top: 6px;
    /* margin-left: -5px; // Account for pseudo dots */
    font-size: 9px;
    line-height: 9px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: ${darken(0.15, theme.colors.lightBorder)};
/*
    &::after {
      content: '.';
      animation: dots ${animationTiming.toString()}ms steps(5, end) infinite;
    }

    @keyframes dots {
      0%,
      20% {
        color: rgba(255, 255, 255, 0);
        text-shadow: 4px 0 0 rgba(255, 255, 255, 0),
          8px 0 0 rgba(255, 255, 255, 0);
      }
      40% {
        color: currentColor;
        text-shadow: 4px 0 0 rgba(255, 255, 255, 0),
          8px 0 0 rgba(255, 255, 255, 0);
      }
      60% {
        text-shadow: 4px 0 0 currentColor, 8px 0 0 rgba(255, 255, 255, 0);
      }
      80%,
      100% {
        text-shadow: 4px 0 0 currentColor, 8px 0 0 currentColor;
      }
    } */
  }
`

const Inner = styled.div`
  display: inline-block;
  position: relative;
  width: ${spinnerSize.toString()}px;
  height: ${spinnerSize.toString()}px;
  text-align: center;
  color: currentColor;

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(spinnerSize * 0.9).toString()}px;
    height: ${(spinnerSize * 0.9).toString()}px;
    margin: ${(spinnerSize * 0.1).toString()}px;
    border: ${(spinnerSize * 0.1).toString()}px solid currentColor;
    border-radius: 50%;
    animation: ring ${animationTiming.toString()}ms cubic-bezier(0.5, 0, 0.5, 1)
      infinite;
    border-color: currentColor transparent transparent transparent;
  }

  span:nth-child(1) {
    animation-delay: -${((animationTiming / 8) * 3).toString()}ms;
  }

  span:nth-child(2) {
    animation-delay: -${((animationTiming / 8) * 2).toString()}ms;
  }

  span:nth-child(3) {
    animation-delay: -${((animationTiming / 8) * 1).toString()}ms;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const LoadingSpinner = props => (
  <Outer {...props}>
    <Inner>
      <span />
      <span />
      <span />
    </Inner>
    {props.noText ? null : <p>Loading</p>}
  </Outer>
)

export default LoadingSpinner
