import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  text-align: center;
`

const ErrorMessage = props => (
  <Wrapper {...props}>
    <h3 className="section-title">Error</h3>
    <p className="headline--intro">
      Our developers have received details about this error and will get it
      fixed. Please try again later.
    </p>
  </Wrapper>
)

export default ErrorMessage
