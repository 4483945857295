import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  text-align: center;

  &,
  a,
  svg {
    color: ${(props) => props.theme.colors.darkText};
  }

  p {
    margin: 0;
  }

   h1 {
    font-size: 3em;
  }

  svg {
    width: 270px;
    margin-bottom: 7px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {

    h1 {
      font-size: 5em;
    }

    svg {
      width: 400px;
      margin-bottom: 14px;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {

    h1 {
      font-size: 7em;
    }

    svg {
      width: 700px;
      margin-bottom: 28px;
    }
  }
`

const Tagline = styled.div`

p {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.02rem;
    font-weight: 300;
    color: white;
    text-transform: uppercase;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    p {
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.03rem;
      font-weight: 300;
      color: white;
      text-transform: uppercase;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    p {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.06rem;
      font-weight: 300;
      color: white;
      text-transform: uppercase;
    }
  }
`

const Location = styled.div`

  margin-top: 55px;

  p {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    margin-top: 95px;

    p {
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: white;
      text-transform: uppercase;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    margin-top: 140px;

    p {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      color: white;
      text-transform: uppercase;
    }
  }
`


const IncentiveTripHeader = (props) => (
  <Wrapper {...props}>
    <h1 className="headline--hero">Past Trips</h1>
    {/*
    <Link to="/events/incentive-trip/">
      <Logo />
    </Link>
    <Tagline>
      <p>Create your next work of art.</p>
    </Tagline>
    <Location>
      <p>Atelier Playa Mujeres<br />Cancun, MX</p>
    </Location>
*/}
  </Wrapper>
)

export default IncentiveTripHeader
