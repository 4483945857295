import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  text-align: center;
`

const NoResultsText = ({ children, ...props }) => (
  <Wrapper className="section" {...props}>
    <p className="headline--intro">{children}</p>
  </Wrapper>
)

export default NoResultsText
