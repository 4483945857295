import axios from 'axios'
import { useEffect, useState } from 'react'

const useAxiosGet = ({ url, trigger }) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  let source = axios.CancelToken.source()
  useEffect(() => {
    try {
      setLoading(true)
      axios
        .get(url, {
          cancelToken: source.token,
        })
        .catch(function(thrown) {
          if (axios.isCancel(thrown)) {
            // console.log(`request cancelled:${thrown.message}`)
          } else {
            setError(thrown)
          }
        })
        .then(a => {
          setResponse(a)
          setLoading(false)
        })
    } catch (e) {
      setResponse(null)
      setError(e)
    }

    // if (source) {
    //   console.log('source defined')
    // } else {
    //   console.log('source NOT defined')
    // }

    return function() {
      // console.log('cleanup of useAxiosGet called')
      // if (source) {
      //   console.log('source in cleanup exists')
      // } else {
      //   console.log('source in cleanup DOES NOT exist')
      // }
      source.cancel()
    }
  }, [trigger])

  return { response, loading, error }
}

export default useAxiosGet
