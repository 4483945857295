/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import StickyNav from './StickyNav'

const Outer = styled.div`
  padding-top: ${props => props.theme.stickyNav.heightSm};

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    padding-top: ${props => props.theme.stickyNav.heightLg};
  }
`

const StickyContainer = ({ id, children, stickyNavLinks, ...props }) => {
  const domEvents = [
    'resize',
    'scroll',
    'touchstart',
    'touchmove',
    'touchend',
    'pageshow',
    'load',
  ]

  const stickyRef = useRef()
  const ref = useRef()

  const handleEvents = () => {
    if (stickyRef && stickyRef.current && ref && ref.current) {
      // @ts-ignore
      const stickyNavHeight = stickyRef.current.offsetHeight
      // @ts-ignore
      const stickyContainerTopFromWinTop = ref.current.getBoundingClientRect()
        .top
      const stickyContainerBottomFromWinTop =
        // @ts-ignore
        ref.current.getBoundingClientRect().bottom - stickyNavHeight
      if (stickyContainerTopFromWinTop < 0) {
        // @ts-ignore
        stickyRef.current.classList.add('is-stuck')
        if (stickyContainerBottomFromWinTop < 0) {
          // @ts-ignore
          stickyRef.current.classList.add('is-past-bottom')
        } else if (stickyContainerBottomFromWinTop >= 0) {
          // @ts-ignore
          stickyRef.current.classList.remove('is-past-bottom')
        }
      } else if (stickyContainerTopFromWinTop >= 0) {
        // @ts-ignore
        stickyRef.current.classList.remove('is-stuck')
        // @ts-ignore
        stickyRef.current.classList.remove('is-past-bottom')
      }
    }
  }

  const handleStickyScrollWrapper = () => {
    if (stickyRef && stickyRef.current) {
      // @ts-ignore
      const scrollWrapper = stickyRef.current.querySelector(
        '.h-overflow-wrapper'
      )
      // @ts-ignore
      const scrollIndicator = stickyRef.current.querySelector(
        '.scroll-indicator'
      )

      // Check for horizontal overflow and show the scroll indicator if needed
      if (
        // @ts-ignore
        scrollWrapper.scrollWidth > stickyRef.current.scrollWidth + 20 &&
        scrollWrapper.scrollLeft < 20
      ) {
        scrollIndicator.classList.add('show')
      } else {
        scrollIndicator.classList.remove('show')
      }
    }
  }

  useEffect(() => {
    const scrollWrapper =
      // @ts-ignore
      stickyRef && stickyRef.current.querySelector('.h-overflow-wrapper')

    const activeLink =
      // @ts-ignore
      scrollWrapper && scrollWrapper.querySelector('.active-page-link')

    activeLink &&
      scrollWrapper.scrollTo({
        left: activeLink.getBoundingClientRect().left,
        behavior: 'smooth',
      })

    function setupEventsHandler() {
      domEvents.forEach(event => {
        window.addEventListener(event, handleEvents, {
          capture: true,
          passive: true,
        })
        window.addEventListener(event, handleStickyScrollWrapper, {
          capture: true,
          passive: true,
        })
      })
      handleEvents()
      handleStickyScrollWrapper()
    }

    setupEventsHandler()

    return function cleanupEvents() {
      domEvents.forEach(event => {
        window.removeEventListener(event, handleEvents)
        window.removeEventListener(event, handleStickyScrollWrapper)
      })
    }
  }, [])

  return (
    <Outer ref={ref} {...props} id={id}>
      <StickyNav ref={stickyRef}>{stickyNavLinks}</StickyNav>
      {children}
    </Outer>
  )
}
export default StickyContainer
