import React, { useMemo, useState } from 'react'

// const pageDefaults = {}

// const bannerDefaults = {
//   visibility: false,
//   animate: true,
// }

const PageContext = React.createContext({})
// const BannerContext = React.createContext(bannerDefaults)

function ApplicationStateProvider({ children, ...props }) {
  const [pageState, setPageState] = useState({ hasStickySiteNav: true })
  const value = useMemo(() => {
    return {
      pageState,
      setPageState,
    }
  }, [pageState])
  return (
    <PageContext.Provider value={value} {...props}>
      {children}
    </PageContext.Provider>
  )
}

// const ApplicationState = ({ children }) => {
//   const [pageState, setPageState] = useState(pageDefaults)
//   // const [siteBanner, setSiteBanner] = useState(bannerDefaults)
//   const pageStateContextValue = useMemo(() => {
//     return {
//       pageState,
//       setPageState,
//     }
//   }, [pageState])

//   return (
//     <PageContext.Provider value={pageStateContextValue}>
//       {/* <BannerContext.Provider value={[siteBanner, setSiteBanner]}> */}
//       {children}
//       {/* </BannerContext.Provider> */}
//     </PageContext.Provider>
//   )
// }

export { PageContext }
// export { BannerContext }

export default ApplicationStateProvider
